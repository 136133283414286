import React from "react";
import "./SubComponents.css";
import { useUltimateTokenContext } from "../../../context/UltimateContext";
import { Tooltip } from "react-tooltip";
export default function UtTokenSupply({ handleSetSession }) {
  const { state, dispatch } = useUltimateTokenContext();

  const handleChangeInput = (field, value) => {
    dispatch({
      type: "UPDATE_FORM_DATA",
      section: "tokenSupply",
      payload: { [field]: value },
    });
  };

  return (
    <section className="token-create-main-cont">
      <div className="box-bg">
        <div className="token-create-heading-cont">
          <div>Token Supply</div>
          <div>Let’s set the supply for your token</div>
        </div>

        <div className="token-supply-input-cont-col2">
          <div className="tsicc2-input">
            <div className="input-heading-cont">
              Initial Supply{" "}
              <span id="utInitialSupply" className="tooltip-design">
                i
              </span>
            </div>
            <input
              type="number"
              onChange={(e) => {
                const value = parseInt(e.target.value);
                if (!isNaN(value)) {
                  const newValue = Math.max(
                    10000,
                    Math.min(value, 10000000000000)
                  );
                  handleChangeInput("initialSupply", newValue);
                }
              }}
              value={state.tokenSupply.initialSupply}
              min={10000}
              max={10000000000000}
              onWheel={(e) => e.target.blur()}
              name="token-name"
            />

            <Tooltip
              anchorSelect="#utInitialSupply"
              className="styletooltip"
              style={{
                backgroundColor: "#12141a",
                borderRadius: "12px",
                zIndex: "11111",
                width: "400px",
                textAlign: "center",
              }}>
              This represents the number of tokens initially available when your
              token launches. It's the starting point for your token's
              circulation within your ecosystem. You might consider factors like
              market demand, token utility, and project goals when determining
              the initial supply.
            </Tooltip>
          </div>

          {/* <div className="tsicc2-input">
            <div className="input-heading-cont">
              Max Supply{" "}
              <span id="utMaxSup" className="tooltip-design">
                i
              </span>
            </div>
            <input
              type="number"
              name="token-name"
              disabled
              onChange={(e) => handleChangeInput("maxSupply", e.target.value)}
              onWheel={(e) => e.target.blur()}
              value={state.tokenSupply.initialSupply}
            />

            <Tooltip
              anchorSelect="#utMaxSup"
              className="styletooltip"
              style={{
                backgroundColor: "#12141a",
                borderRadius: "12px",
                zIndex: "11111",
                width: "300px",
                textAlign: "center",
              }}>
              Max Supply for the token!
            </Tooltip>
          </div> */}
        </div>
      </div>

      <div className="nxt-prev-btn-cont">
        <button
          className="grey-btn"
          onClick={() => handleSetSession("UtAdditionalFeature")}>
          Previous
        </button>
        <button
          className="gradient-btn"
          onClick={() => handleSetSession("UtLiquidityOption")}>
          Next
        </button>
      </div>
    </section>
  );
}
