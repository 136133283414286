import React, { useState, useEffect } from "react";
import "../../ultimate-token/sub-components/SubComponents.css";
import Toggle from "../../../utills/Toggle/Toggle";
import MyInput from "../../ultimate-token/sub-components/MyInput";
import { Tooltip } from "react-tooltip";
import { useDeflationaryTokenContext } from "../../../context/DeflationaryContext";
import axios from "axios";

export default function DtLiquidityOptions({ handleSetSession }) {
  const { state, dispatch } = useDeflationaryTokenContext();
  const [usdEthPrice, setUsdEthPrice] = useState(1);
  const [inputError, setInputError] = useState({
    ethAmount: "",
    exchange: "",
  });

  const handleInputChange = (field, value) => {
    dispatch({
      type: "UPDATE",
      section: "liquidityOption",
      payload: { [field]: value },
    });

    // Validate input dynamically
    if (field === "ethAmount") {
      if (!value) {
        setInputError((prevData) => ({
          ...prevData,
          ethAmount: "Please input amount to add liquidity!",
        }));
      } else {
        setInputError((prevData) => ({ ...prevData, ethAmount: "" }));
      }
    } else if (field === "dexExchange") {
      if (!value) {
        setInputError((prevData) => ({
          ...prevData,
          exchange: "Please select exchange!",
        }));
      } else {
        setInputError((prevData) => ({ ...prevData, exchange: "" }));
      }
    }
  };

  const setToggleValue = (field, data) => {
    handleInputChange(field, data);
  };

  const handleChange = (field, value) => {
    handleInputChange(field, value);
  };

  const handleNextComponent = () => {
    let isValid = true;

    if (state.liquidityOption.addLiquidity) {
      if (!state.liquidityOption.ethAmount) {
        setInputError((prevData) => ({
          ...prevData,
          ethAmount: "Please input amount to add liquidity!",
        }));

        isValid = false;
      } else if (!state.liquidityOption.dexExchange) {
        setInputError((prevData) => ({
          ...prevData,
          exchange: "Please select exchange!",
        }));

        isValid = false;
      } else {
        setInputError((prevData) => ({ ...prevData, exchange: "" }));
      }
    }

    if (isValid) {
      handleSetSession("DtDeployTOken");
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=usd"
        );
        setUsdEthPrice(response?.data?.ethereum?.usd);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <section className="token-create-main-cont">
      <div className="box-bg tc-liq-cont">
        <div className="token-create-heading-cont">
          <div>Liquidity Options</div>
          <div>Quickly add liquidity for your token.</div>
        </div>

        <div className="tc-toggle-cont tc-box-responsive liquidity-option-toggle">
          <div>
            <div className="input-heading-cont">
              Add Initial Liquidity{" "}
              <span id="dtInitialLiq" className="tooltip-design">
                i
              </span>
            </div>
            <div>
              Automatically create and fund the liquidity pool, this will allow
              users to swap your token.
            </div>
          </div>

          <Toggle
            setToggleValue={setToggleValue}
            value={state.liquidityOption.addLiquidity}
            keyValue={"addLiquidity"}
          />

          <Tooltip
            anchorSelect="#dtInitialLiq"
            className="styletooltip"
            style={{
              backgroundColor: "#12141a",
              borderRadius: "12px",
              zIndex: "11111",
              width: "350px",
              textAlign: "center",
            }}>
            Automatically create and fund the liquidity pool, enabling users to
            swap your token seamlessly. This option is ideal for launching your
            token with liquidity already available, ensuring immediate trading
            opportunities for users.
          </Tooltip>
        </div>

        {state.liquidityOption.addLiquidity && (
          <>
            <div className="tc-liq-section-cont">
              <div className="tc-toggle-cont">
                <div>
                  <div className="input-heading-cont">
                    Token Paring & Funding
                    <div>
                      <span id="dtTokenPairFunding" className="tooltip-design">
                        i
                      </span>
                    </div>
                  </div>
                  <div>
                    Pair a percentage of your token supply with ETH to fund the
                    liquidity pool.
                  </div>

                  <Tooltip
                    anchorSelect="#dtTokenPairFunding"
                    className="styletooltip"
                    style={{
                      backgroundColor: "#12141a",
                      borderRadius: "12px",
                      zIndex: "11111",
                      width: "350px",
                      textAlign: "center",
                    }}>
                    Pair a percentage of your token supply with ETH to fund the
                    liquidity pool. A common pairing involves allocating at
                    least 10% of your token supply with a minimum of 1 ETH. This
                    process ensures that your token has sufficient liquidity to
                    support trading activities upon launch.
                  </Tooltip>
                </div>
              </div>

              <div className="tc-liq-token-supply">
                <div>
                  <div className="input-heading-cont">
                    Token Supply (%):{" "}
                    <span id="dtTokenSupply" className="tooltip-design">
                      i
                    </span>
                  </div>
                  <div className="tc-liq-total-supply-bar-cont">
                    <MyInput
                      min={1}
                      max={100}
                      value={state.liquidityOption.tokenAmount}
                      param={"tokenAmount"}
                      handleChange={handleChange}
                    />
                    <div>{state.liquidityOption.tokenAmount}%</div>
                  </div>

                  <Tooltip
                    anchorSelect="#dtTokenSupply"
                    className="styletooltip"
                    style={{
                      backgroundColor: "#12141a",
                      borderRadius: "12px",
                      zIndex: "11111",
                      width: "350px",
                      textAlign: "center",
                    }}>
                    These are soon to be your newly created tokens. You'll
                    allocate a portion of your token supply alongside ETH to
                    create a trading pair on the DEX. You're allocating a
                    portion of your token supply alongside ETH to create a
                    trading pair on the DEX, enabling users to exchange your
                    tokens for ETH and vice versa. Think of tokens as digital
                    representations of value or utility within a specific
                    ecosystem.
                  </Tooltip>
                </div>

                <div className="tsicc2-input">
                  <div className="input-heading-cont">
                    ETH Amount:{" "}
                    <span id="dtEthAmount" className="tooltip-design">
                      i
                    </span>
                  </div>
                  <input
                    type="number"
                    name="ethAmount"
                    value={state.liquidityOption.ethAmount}
                    onChange={(e) =>
                      handleInputChange("ethAmount", e.target.value)
                    }
                    onWheel={(e) => e.target.blur()}
                  />
                  <Tooltip
                    anchorSelect="#dtEthAmount"
                    className="styletooltip"
                    style={{
                      backgroundColor: "#12141a",
                      borderRadius: "12px",
                      zIndex: "11111",
                      width: "350px",
                      textAlign: "center",
                    }}>
                    ETH is the cryptocurrency used on the Ethereum blockchain.
                    In this context, it is used to facilitate transactions, pay
                    for gas fees (transaction fees), and provide liquidity for
                    decentralised exchanges (DEXs) like Bluedex. Here, ETH
                    represents the value you'll provide to fund liquidity for
                    your token.
                  </Tooltip>

                  {inputError.ethAmount && (
                    <p className="input_error">{inputError.ethAmount}</p>
                  )}
                </div>
              </div>

              <div className="tc-liq-tok-pr-cont">
                <div className="tc-liq-tok-price">
                  <div>
                    <div>Token</div>
                    <div>
                      {(state.tokenSupply.initialSupply *
                        state.liquidityOption.tokenAmount) /
                        100}
                    </div>
                    <div className="tc-liq-circle-design">
                      <div>+</div>
                    </div>
                  </div>
                  <div>
                    <div>ETH</div>
                    <div>
                      {state.liquidityOption.ethAmount
                        ? state.liquidityOption.ethAmount
                        : ""}
                    </div>
                  </div>
                </div>
              </div>

              <div className="tc-liq-tok-text">
                Based on the selected options and values above, your token will
                launch with the following initial parameters, including the
                starting market cap and the initial token price.
              </div>

              <div className="tc-liq-tok-price tc-liq-tk-pr-sc-cont">
                <div>
                  <div>Launch Market Cap</div>
                  <div>
                    {(
                      (state.tokenSupply.initialSupply /
                        ((state.tokenSupply.initialSupply *
                          state.liquidityOption.tokenAmount) /
                          100)) *
                      state.liquidityOption.ethAmount
                    ).toFixed(2)}{" "}
                    ETH (~
                    {(
                      (
                        (state.tokenSupply.initialSupply /
                          ((state.tokenSupply.initialSupply *
                            state.liquidityOption.tokenAmount) /
                            100)) *
                        state.liquidityOption.ethAmount
                      ).toFixed(2) * usdEthPrice
                    ).toFixed("2")}{" "}
                    USD)
                  </div>
                </div>
                <div>
                  <div>Launch Token Price</div>
                  <div>
                    {(
                      (state.tokenSupply.initialSupply /
                        (state.tokenSupply.initialSupply *
                          state.liquidityOption.tokenAmount) /
                        100) *
                      state.liquidityOption.ethAmount
                    ).toFixed(6)}{" "}
                    (~
                    {(
                      (
                        (state.tokenSupply.initialSupply /
                          (state.tokenSupply.initialSupply *
                            state.liquidityOption.tokenAmount) /
                          100) *
                        state.liquidityOption.ethAmount
                      ).toFixed(6) * usdEthPrice
                    ).toFixed("2")}{" "}
                    USD)
                  </div>
                </div>
              </div>
            </div>

            <div className="ticc2-select-cont">
              <div className="input-heading-cont">
                Exchange (DEX):{" "}
                <span id="dtExchangeLiq" className="tooltip-design">
                  i
                </span>
              </div>
              {/* <div>
                We'll use the selected DEX to create your liquidity pair and
                seed the initial pool.
              </div> */}
              <select
                value={state.liquidityOption.dexExchange || ""}
                onChange={(e) =>
                  handleInputChange("dexExchange", e.target.value)
                }>
                <option disabled value="">
                  Please Select
                </option>
                <option value="0x1926f2BEc4cd34613ccAaC82911204B87cf590a2">
                  Bluedex (Main-net)
                </option>
                <option value="0xAEDfd05a6d0129670751b379C1623a0FD0bFBa16">
                  Bluedex (Test-net)
                </option>
              </select>

              <Tooltip
                anchorSelect="#dtExchangeLiq"
                className="styletooltip"
                style={{
                  backgroundColor: "#12141a",
                  borderRadius: "12px",
                  zIndex: "11111",
                  width: "350px",
                  textAlign: "center",
                }}>
                We'll utilise the selected decentralised exchange (DEX) to
                create your liquidity pair and seed the initial pool. This
                ensures that your token is integrated seamlessly into the DEX
                environment, allowing users to trade your token efficiently.
              </Tooltip>

              {inputError.exchange && (
                <p className="input_error">{inputError.exchange}</p>
              )}
            </div>

            <div className="tc-liq-action-cont ticc2-select-cont">
              <div
                style={{ position: "relative" }}
                className="input-heading-cont">
                Liquidity Action & Ownership:{" "}
                <div className="liquidy-coming-soon">
                  <div className="tc-triangle-design-liq"></div>{" "}
                  <div className="tc-badge-liq">Coming Soon</div>
                </div>
                <span
                  id="dtLiqActionOwn"
                  className="tooltip-design tooltip-design-liq-section">
                  i
                </span>
                <Tooltip
                  anchorSelect="#dtLiqActionOwn"
                  className="styletooltip"
                  style={{
                    backgroundColor: "#12141a",
                    borderRadius: "12px",
                    zIndex: "11111",
                    width: "350px",
                    textAlign: "center",
                  }}>
                  Coming soon! You will have the ability to burn or lock the
                  liquidity on deployment. This provides flexibility in managing
                  the liquidity of your token, allowing you to implement
                  strategies that align with your project's objectives and
                  requirements.
                </Tooltip>
              </div>

              <div></div>

              <div>
                <div
                  className={` ${
                    state.liquidityOption.liquidityOwnership === "None"
                      ? "tc-liq-non-brn-lck-active"
                      : "tc-liq-non-brn-lck"
                  }`}
                  onClick={() =>
                    handleInputChange("liquidityOwnership", "None")
                  }>
                  None
                </div>
                <div
                  className={` ${
                    state.liquidityOption.liquidityOwnership === "Burn"
                      ? "tc-liq-non-brn-lck-active"
                      : "tc-liq-non-brn-lck"
                  }`}
                  onClick={() =>
                    handleInputChange("liquidityOwnership", "Burn")
                  }>
                  Burn
                </div>
                <div
                  className={` ${
                    state.liquidityOption.liquidityOwnership === "Lock"
                      ? "tc-liq-non-brn-lck-active"
                      : "tc-liq-non-brn-lck"
                  }`}
                  onClick={() =>
                    handleInputChange("liquidityOwnership", "Lock")
                  }>
                  Lock
                </div>
              </div>
            </div>
          </>
        )}
      </div>

      <div className="nxt-prev-btn-cont">
        <button
          className="grey-btn"
          onClick={() => handleSetSession("DtConfigureFee")}>
          Previous
        </button>
        <button className="gradient-btn" onClick={handleNextComponent}>
          Next
        </button>
      </div>
    </section>
  );
}
