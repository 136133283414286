import React, { useState } from "react";
import "./SubComponents.css";
import Toggle from "../../../utills/Toggle/Toggle.js";
import { Tooltip } from "react-tooltip";
import { useUltimateTokenContext } from "../../../context/UltimateContext.js";

export default function UtAdditionalFeature({ handleSetSession }) {
  const { state, dispatch } = useUltimateTokenContext();

  const handleInputChage = (field, value) => {
    dispatch({
      type: "UPDATE_FORM_DATA",
      section: "additional",
      payload: { [field]: value },
    });
  };

  const setToggleValue = (id, data) => {
    handleInputChage(id, data);
  };

  const ToggleMapData = {
    // mintReport: {
    //   id: 'mintReport',
    //   text1: "Minting Support",
    //   text2: "Enable minting for your token, only the owner can mint.",
    //   isTrue: state.additional.unlimitedReport
    //     ? true
    //     : state.additional.mintReport,
    //     tooltipText: 'Select to get mint support'
    // },
    burnReport: {
      id: "burnReport",
      text1: "Burning Support",
      text2: "Enable burning for your token.",
      isTrue: state.additional.burnReport,
      tooltipText:
        " Enable burning for your token, allowing tokens to be permanently removed from circulation. Burning can help manage token supply, increase scarcity, and potentially enhance token value over time. Burning operations can be executed through the relevant blockchain explorer for the deployed chain, such as BaseScan.",
    },
    // pauseReport: {
    //   id: 'pauseReport',
    //   text1: "Pausing Support",
    //   text2: "Allows you to pause the token.",
    //   isTrue: state.additional.pauseReport,
    //   tooltipText: 'Select to get pause support'
    // },
    // unlimitedReport: {
    //   id: 'unlimitedReport',
    //   text1: "Unlimited Supply",
    //   text2:
    //     "Enable unlimited supply for your token, requires minting support.",
    //   isTrue: state.additional.unlimitedReport,
    //   tooltipText: 'Select to get unlimited support'
    // },
  };

  return (
    <section className="token-create-main-cont additional-feature-card-main">
      <div>
        <div className="tc-additional-feature-heading-cont">
          <div>Additional Features</div>
          <div>Enable additional features for your token.</div>
        </div>

        {Object.entries(ToggleMapData).map(([key, value], index) => (
          <div
            className={`tc-toggle-cont-additional-feature toggle-parent-${index}`}
            key={key}>
            <div>
              <div className="input-heading-cont">
                {value.text1}{" "}
                <span id={value.id} className="tooltip-design">
                  i
                </span>
              </div>
              <div>{value.text2}</div>
            </div>

            <Toggle
              setToggleValue={setToggleValue}
              value={value.isTrue}
              keyValue={key}
            />

            <Tooltip
              anchorSelect={`#${value.id}`}
              className="styletooltip"
              style={{
                backgroundColor: "#12141a",
                borderRadius: "12px",
                zIndex: "11111",
                width: "400px",
                textAlign: "center",
              }}>
              {value.tooltipText}
            </Tooltip>
          </div>
        ))}
      </div>

      <div className="nxt-prev-btn-cont">
        <button
          className="grey-btn"
          onClick={() => handleSetSession("UtTokenInformation")}>
          Previous
        </button>
        <button
          className="gradient-btn"
          onClick={() => handleSetSession("UtTokenSupply")}>
          Next
        </button>
      </div>
    </section>
  );
}
