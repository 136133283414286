import React, { useState } from "react";
import "../ultimate-token/UltimateToken.css";
import TCTopContent from "../../utills/token-creation/TCTopContent/TCTopContent";
import DtTokenInformation from "./sub-components/DtTokenInformation";
import DtAdditionalFeatures from "./sub-components/DtAdditionalFeatures";
import DtTokenSupply from "./sub-components/DtTokenSupply";
import DtLiquidityOptions from "./sub-components/DtLiquidityOptions";
import DtDeployTOken from "./sub-components/DtDeployTOken";
import DtConfigureFees from "./sub-components/DtConfigureFees";
import DtReviewToken from "./sub-components/DtReviewToken";

export default function DeflationaryToken() {
  const [activeSession, setActiveSesstion] = useState("DtTokenInformation");
  const [barPercentage, setBarPercentage] = useState(0);
  const dtTopData = {
    heading: "Deflationary Token",
    subHeading: `The Deflationary Token merges the ERC20 standard's reliability with enhanced utilities for a dynamic market presence. Customise your token to fit your strategy, setting limits on wallet holdings, transactions, and integrating buy and sell fees among other options. It's designed for those who want to actively manage their token's economy and encourage a healthier circulation.`,
    badgeData: [
      "ERC20",
      "Supply Limits",
      "Deflationary",
      "Burn Fee",
      "Liquidity Fee",
      "Marketing Fee",
      "Wallet Limits",
      "Transaction Limits",
    ],
  };

  const handleSetSession = (data) => {
    setActiveSesstion(data);

    if (data === "DtTokenInformation") {
      setBarPercentage(0);
    } else if (data === "DtAdditionalFeature") {
      setBarPercentage(22);
    } else if (data === "DtTokenSupply") {
      setBarPercentage(42);
    } else if (data === "DtConfigureFee") {
      setBarPercentage(60);
    } else if (data === "DtLiquidityOption") {
      setBarPercentage(80);
    } else if (data === "DtDeployTOken") {
      setBarPercentage(100);
    }
  };

  return (
      <section className="ultimate-token-container">
        <TCTopContent TCTopData={dtTopData} />

        {activeSession !== "DtReviewToken" && (
          <div className="token-step-area">
            <div className="token-step-single">
              <h4
                className={`${
                  activeSession === "DtTokenInformation"
                    ? "number-active"
                    : activeSession === "DtAdditionalFeature" ||
                      activeSession === "DtTokenSupply" ||
                      activeSession === "DtConfigureFee" ||
                      activeSession === "DtLiquidityOption" ||
                      activeSession === "DtDeployTOken"
                    ? "number-visited"
                    : "number"
                }`}>
                1
              </h4>
              <p className={activeSession === "DtTokenInformation" ? "info_active" : "info"}>Token Information</p>
            </div>
            <div className="token-step-single">
              <h4
                className={`${
                  activeSession === "DtAdditionalFeature"
                    ? "number-active"
                    : activeSession === "DtTokenSupply" ||
                      activeSession === "DtConfigureFee" ||
                      activeSession === "DtLiquidityOption" ||
                      activeSession === "DtDeployTOken"
                    ? "number-visited"
                    : "number"
                }`}>
                2
              </h4>
              <p className={activeSession === "DtAdditionalFeature" ? "info_active" : "info"}>Additional Features</p>
            </div>
            <div className="token-step-single">
              <h4
                className={`${
                  activeSession === "DtTokenSupply"
                    ? "number-active"
                    : activeSession === "DtConfigureFee" ||
                      activeSession === "DtLiquidityOption" ||
                      activeSession === "DtDeployTOken"
                    ? "number-visited"
                    : "number"
                }`}>
                3
              </h4>
              <p className={activeSession === "DtTokenSupply" ? "info_active" : "info"}>Token Supply</p>
            </div>
            <div className="token-step-single">
              <h4
                className={`${
                  activeSession === "DtConfigureFee"
                    ? "number-active"
                    : activeSession === "DtLiquidityOption" ||
                      activeSession === "DtDeployTOken"
                    ? "number-visited"
                    : "number"
                }`}>
                4
              </h4>
              <p className={activeSession === "DtConfigureFee" ? "info_active" : "info"}>Configure Fees</p>
            </div>
            <div className="token-step-single">
              <h4
                className={`${
                  activeSession === "DtLiquidityOption"
                    ? "number-active"
                    : activeSession === "DtDeployTOken"
                    ? "number-visited"
                    : "number"
                }`}>
                5
              </h4>
              <p className={activeSession === "DtLiquidityOption" ? "info_active" : "info"}>Liquidity Options</p>
            </div>
            <div className="token-step-single">
              <h4
                className={`${
                  activeSession === "DtDeployTOken" ? "number-active" : "number"
                }`}>
                6
              </h4>
              <p className={activeSession === "DtDeployTOken" ? "info_active" : "info"}>Deploy Token</p>
            </div>

            <div className="progress">
              <div
                className="progress-bar progress-bar-success"
                style={{ width: `${barPercentage}%` }}></div>
            </div>
          </div>
        )}

        {activeSession === "DtTokenInformation" && (
          <DtTokenInformation handleSetSession={handleSetSession} />
        )}
        {activeSession === "DtAdditionalFeature" && (
          <DtAdditionalFeatures handleSetSession={handleSetSession} />
        )}
        {activeSession === "DtTokenSupply" && (
          <DtTokenSupply handleSetSession={handleSetSession} />
        )}
        {activeSession === "DtConfigureFee" && (
          <DtConfigureFees handleSetSession={handleSetSession} />
        )}
        {activeSession === "DtLiquidityOption" && (
          <DtLiquidityOptions handleSetSession={handleSetSession} />
        )}
        {activeSession === "DtDeployTOken" && (
          <DtDeployTOken handleSetSession={handleSetSession} />
        )}
        {activeSession === "DtReviewToken" && (
          <DtReviewToken handleSetSession={handleSetSession} />
        )}
      </section>
  );
}
