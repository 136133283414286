import React from "react";
import "./NetworkDropdown.css";
import baseIcon from "../../assets/network_iocns/base_network_icon.png";
import bnbIcon from "../../assets/network_iocns/bnb-logo.png";
import ethIcon from "../../assets/network_iocns/ethereum_icon.png";
import tickIcon from "../../assets/network_iocns/blue_tick_icon.png";
import { useAccount, useChainId } from "wagmi";
import { useUltimateTokenContext } from "../../context/UltimateContext";

export default function NetworkDropdown() {
  const { address } = useAccount();
  const chainId = useChainId();

  const { state, dispatch } = useUltimateTokenContext();

  const handleInputChage = (field, value) => {
    dispatch({
      type: "UPDATE_FORM_DATA",
      section: "tokenInfo",
      payload: { [field]: value },
    });
  };

  const networkList = [
    {
      networkName: "Base",
      networkIcon: baseIcon,
      activeStatus: true,
      networkId: 8453,
    },
    {
      networkName: "Base Sepolia",
      networkIcon: baseIcon,
      activeStatus: true,
      networkId: 84532,
    },
    {
      networkName: "BNB Chain",
      networkIcon: bnbIcon,
      networkId: 0,
      comingSoonStatus: true,
    },
    {
      networkName: "Ethereum",
      networkIcon: ethIcon,
      networkId: 0,
      comingSoonStatus: true,
    },
  ];

  return (
    <section className="network-dropdown-container">
      {networkList.map((value, index) => (
        <div className="ndnl-cont-active" key={index}>
          <div
            className="nd-network-list"
            onClick={() => handleInputChage("chainNetwork", value.networkName)}>
            <img
              src={value.networkIcon}
              alt=""
              className="nd-network-icon"
              height={20}
              width={20}
            />

            <div>
              <div
                className={
                  value.comingSoonStatus
                    ? "ndnn-coming-soon"
                    : "nd-network-name"
                }>
                {value.networkName}
              </div>

              {value.comingSoonStatus && (
                <div className="coming-soon">Coming Soon</div>
              )}
            </div>
          </div>

          {address && chainId === value.networkId && (
            <img src={tickIcon} alt="" height={23} width={23} />
          )}
        </div>
      ))}
    </section>
  );
}
