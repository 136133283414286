import React from "react";
import { Link } from "react-router-dom";
import BlueLogoGrey from "../../../assets/bluecore_logo_fade.png";
import "./BlueDexLinks.css";

export default function BlueDexLinks() {
  const Links = [
    { text: "Token Builder", link: "/create-token" },
    { text: "Core Token", link: "/create-token/core-token" },
    { text: "Terms & Conditions", link: "/terms-and-conditions" },
    { text: "FAQ", link: "/faqs" },
    { text: "Contact", link: "/contact" },
    { text: "Deflationary Token", link: "/create-token/deflationary-token" },
    {
      text: "Privacy Policy",
      link: "https://app.termly.io/policy-viewer/policy.html?policyUUID=379b1306-05c5-43d6-a847-2a3708ca4cbe",
    },
    // { text: "Dividend Token", link: "/create-token/dividend-token" },
  ];

  const handleTop = () => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <section className="blue-dex-links-container">
      <div>
        <img
          src={BlueLogoGrey}
          alt=""
          height={50}
          width={50}
          style={{ marginRight: "10px" }}
        />
        <div>BLUEPRINT</div>
      </div>

      <div className="bd-links-section">
        {Links.map((value, key) => (
          <div key={key}>
            {value.text === "Privacy Policy" ? (
              <a href={value.link} target="_blank">
                {value.text}
              </a>
            ) : (
              <Link
                to={`${value.link}`}
                onClick={value.link === "/create-token" && handleTop}>
                {value.text}
              </Link>
            )}
          </div>
        ))}
      </div>
    </section>
  );
}
