export default function NetworkData(network) {
  const networkDetails = {
    Ethereum: {
      chainName: "Ethereum",
      rpcUrl: "https://ethereum-rpc.publicnode.com",
      currencyName: "Ether",
      currencySymbol: "ETH",
      currencyDecimals: 18,
      blockExplorerUrls: "https://etherscan.io",
    },
    BNB: {
      chainName: "BNB Smart Chain",
      rpcUrl: "https://bsc-rpc.publicnode.com",
      currencyName: "BNB",
      currencySymbol: "BNB",
      currencyDecimals: 18,
      blockExplorerUrls: "https://bscscan.com/",
    },
    Base: {
      chainName: "Base",
      rpcUrl: "https://base-rpc.publicnode.com",
      currencyName: "Ether",
      currencySymbol: "ETH",
      currencyDecimals: 18,
      blockExplorerUrls: "https://basescan.org/",
    },
    Polygon: {
      chainName: "Polygon",
      rpcUrl: "https://polygon-bor-rpc.publicnode.com",
      currencyName: "MATIC",
      currencySymbol: "MATIC",
      currencyDecimals: 18,
      blockExplorerUrls: "https://polygonscan.com/",
    },
    OPMainnet: {
      chainName: "OP Mainnet",
      rpcUrl: "https://optimism-rpc.publicnode.com",
      currencyName: "Ether",
      currencySymbol: "ETH",
      currencyDecimals: 18,
      blockExplorerUrls: "https://optimistic.etherscan.io/",
    },
    Arbitrum: {
      chainName: "Arbitrum One",
      rpcUrl: "https://arbitrum-one-rpc.publicnode.com",
      currencyName: "Ether",
      currencySymbol: "ETH",
      currencyDecimals: 18,
      blockExplorerUrls: "https://arbiscan.io/",
    },
    Avalanche: {
      chainName: "Avalanche C-Chain",
      rpcUrl: "https://avalanche-c-chain-rpc.publicnode.com",
      currencyName: "AVAX",
      currencySymbol: "AVAX",
      currencyDecimals: 18,
      blockExplorerUrls: "https://snowtrace.io/",
    },
    Sepolia: {
      chainName: "Sepolia",
      rpcUrl: "https://ethereum-sepolia-rpc.publicnode.com",
      currencyName: "Ether",
      currencySymbol: "ETH",
      currencyDecimals: 18,
      blockExplorerUrls: "https://sepolia.etherscan.io/",
    },
    BNBTestnet: {
      chainName: "BSC Testnet",
      rpcUrl: "https://data-seed-prebsc-1-s1.binance.org:8545/",
      currencyName: "Binance",
      currencySymbol: "tBNB",
      currencyDecimals: 18,
      blockExplorerUrls: "https://testnet.bscscan.com",
    },
    baseSepolia: {
      chainName: "Base Sepolia",
      rpcUrl: "https://base-sepolia-rpc.publicnode.com",
      currencyName: "Ether",
      currencySymbol: "ETH",
      currencyDecimals: 18,
      blockExplorerUrls: "https://sepolia.basescan.org/",
    },
  };

  if (network === "Ethereum") {
    return networkDetails.Ethereum;
  } else if (network === "BNB Smart Chain") {
    return networkDetails.BNB;
  } else if (network === "Polygon") {
    return networkDetails.Polygon;
  } else if (network === "Base") {
    return networkDetails.Base;
  } else if (network === "OP Mainnet") {
    return networkDetails.OPMainnet;
  } else if (network === "Arbitrum") {
    return networkDetails.Arbitrum;
  } else if (network === "Avalanche") {
    return networkDetails.Avalanche;
  } else if (network === "Sepolia") {
    return networkDetails.Sepolia;
  } else if (network === "BNB Testnet") {
    return networkDetails.BNBTestnet;
  } else if (network === "Base Sepolia") {
    return networkDetails.baseSepolia;
  }
}
