import React from "react";
import SuccessIcon from "../../assets/success-mark 1.png";
import "./Modal.css";
import ContractDeployed from "./ContractDeployed";
import { useChainId } from "wagmi";

export default function LiquidityAddedModal({ depContDetails }) {
  const chainId = useChainId();

  const addTokenToMetaMask = async (tokenAddress, tokenSymbol, decimals) => {
    try {
      // Request MetaMask to add the token
      await window.ethereum.request({
        method: "wallet_watchAsset",
        params: {
          type: "ERC20",
          options: {
            address: tokenAddress,
            symbol: tokenSymbol,
            decimals: decimals,
          },
        },
      });
    } catch (error) {
      console.error("Error adding token to MetaMask:", error);
    }
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          color: "#ffffff",
          marginTop: "20px",
        }}>
        <div className="popup_container">
          <img className="success_icon_img" src={SuccessIcon} alt="" />
          <div className="dep_suc_cont">
            <p style={{ display: "inline-block" }} className="suc_modal_txt1">
              Liquidity Added Successfully!
            </p>
            <p className="suc_modal_txt2">
              Pair Address: {depContDetails?.pairAddress}
            </p>

            <a
              href={`${
                chainId === 84532
                  ? "https://sepolia.basescan.org"
                  : chainId === 8453 && "https://basescan.org"
              }/tx/${depContDetails?.addLiquidityHash}`}
              target="_blank"
              className="vw_ln_tkn">
              <p className="vw_ln_tkn_txt">View Transactions on Explorer</p>
            </a>

            <a
              href={`https://bluedex.xyz/#/pools/v2`}
              target="_blank"
              className="vw_ln_tkn">
              <p className="vw_ln_tkn_txt">View Token on Bluedex</p>
            </a>

            <div
              onClick={() =>
                addTokenToMetaMask(depContDetails?.pairAddress, "BDEX-V2", 18)
              }
              className="vw_ln_tkn">
              <p className="vw_ln_tkn_txt">Add Pair in Your Wallet</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
