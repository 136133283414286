import React from "react";
import "./Footer.css";
import { Link } from "react-router-dom";
import GithubIcon from "../../../assets/create-token/github-icon.png";
import XIcon from "../../../assets/create-token/x-icon.png";
import BlueprintLogo from "../../../assets/bluecore_logo_fade.png";

export default function Footer() {
  return (
    <section className="footer-container">
      <div className="footer">
        <div className="footer-typo">
          &copy; 2024 Bluecore. All rights reserved.
        </div>

        <div className="footer-brnading">
          <img src={BlueprintLogo} alt="" height={50} width={50} style={{marginRight: '10px'}}/>

          <div>
            <p>BLUEPRINT</p>
            <p>BY BLUECORE LABS</p>
          </div>
        </div>

        <div>
          <Link
            to={"https://twitter.com/BluecoreLabs"}
            className="footer-x-link"
            target="_blank">
            {" "}
            <img style={{ width: "24px", height: "24px" }} src={XIcon} alt="" />
          </Link>
          <Link to={"https://github.com/"} target="_blank">
            {" "}
            <img
              style={{ width: "24px", height: "24px" }}
              src={GithubIcon}
              alt=""
            />
          </Link>
        </div>
      </div>
    </section>
  );
}
