import React, { useEffect, useState } from "react";
import "./UltimateToken.css";
import TCTopContent from "../../utills/token-creation/TCTopContent/TCTopContent";
import UtTokenInformation from "./sub-components/UtTokenInformation";
import UtAdditionalFeature from "./sub-components/UtAdditionalFeature";
import UtTokenSupply from "./sub-components/UtTokenSupply";
import UtLiquidityOptions from "./sub-components/UtLiquidityOptions";
import UtDeployToken from "./sub-components/UtDeployToken";
import UtReviewToken from "./sub-components/UtReviewToken";

export default function UltimateToken() {
  const [activeSession, setActiveSesstion] = useState("UtTokenInformation");
  const [barPercentage, setBarPercentage] = useState(0);
  const utTopData = {
    heading: "Core Token",
    subHeading: `The Core Token is built on the ERC20 standard and offers essential features plus added flexibility for customisation. It's the perfect starting point for anyone looking to create their token without needing advanced knowledge, providing a straightforward path to ownership and supply management.`,
    badgeData: [
      "ERC20",
      "Supply Limits",
      "Ownership",
      "Transfer Token",
      // "Minting",
      "Burning",
      // "Pausable",
    ],
  };

  const handleSetSession = (data) => {
    setActiveSesstion(data);

    if (data === "UtTokenInformation") {
      setBarPercentage(0);
    } else if (data === "UtAdditionalFeature") {
      setBarPercentage(30);
    } else if (data === "UtTokenSupply") {
      setBarPercentage(50);
    } else if (data === "UtLiquidityOption") {
      setBarPercentage(75);
    } else if (data === "UtDeployToken") {
      setBarPercentage(100);
    }
  };

  useEffect(() => {
    if (activeSession === "UtReviewToken") {
      window.scroll({
        top: 0,
        behavior: "smooth",
      });
    }
  }, [activeSession]);

  return (
    <section className="ultimate-token-container">
      <TCTopContent TCTopData={utTopData} />

      {activeSession !== "UtReviewToken" && (
        <div className="token-step-area">
          <div className="token-step-single">
            <h4
              className={`${
                activeSession === "UtTokenInformation"
                  ? "number-active"
                  : activeSession === "UtAdditionalFeature" ||
                    activeSession === "UtTokenSupply" ||
                    activeSession === "UtLiquidityOption" ||
                    activeSession === "UtDeployToken"
                  ? "number-visited"
                  : "number"
              }`}>
              1
            </h4>
            <p className={activeSession === "UtTokenInformation" ? "info_active" : "info"}>Token Information</p>
          </div>
          <div className="token-step-single">
            <h4
              className={`${
                activeSession === "UtAdditionalFeature"
                  ? "number-active"
                  : activeSession === "UtTokenSupply" ||
                    activeSession === "UtLiquidityOption" ||
                    activeSession === "UtDeployToken"
                  ? "number-visited"
                  : "number"
              }`}>
              2
            </h4>
            <p className={activeSession === "UtAdditionalFeature" ? "info_active" : "info"}>Additional Features</p>
          </div>
          <div className="token-step-single">
            <h4
              className={`${
                activeSession === "UtTokenSupply"
                  ? "number-active"
                  : activeSession === "UtLiquidityOption" ||
                    activeSession === "UtDeployToken"
                  ? "number-visited"
                  : "number"
              }`}>
              3
            </h4>
            <p className={activeSession === "UtTokenSupply" ? "info_active" : "info"}>Token Supply</p>
          </div>
          <div className="token-step-single">
            <h4
              className={`${
                activeSession === "UtLiquidityOption"
                  ? "number-active"
                  : activeSession === "UtDeployToken"
                  ? "number-visited"
                  : "number"
              }`}>
              4
            </h4>
            <p className={activeSession === "UtLiquidityOption" ? "info_active" : "info"}>Liquidity Options</p>
          </div>
          <div className="token-step-single">
            <h4
              className={`${
                activeSession === "UtDeployToken" ? "number-active" : "number"
              }`}>
              5
            </h4>
            <p className={activeSession === "UtDeployToken" ? "info_active" : "info"}>Deploy Token</p>
          </div>

          <div className="progress">
            <div
              className="progress-bar progress-bar-success"
              style={{ width: `${barPercentage}%` }}></div>
          </div>
        </div>
      )}

      {activeSession === "UtTokenInformation" && (
        <UtTokenInformation handleSetSession={handleSetSession} />
      )}
      {activeSession === "UtAdditionalFeature" && (
        <UtAdditionalFeature handleSetSession={handleSetSession} />
      )}
      {activeSession === "UtTokenSupply" && (
        <UtTokenSupply handleSetSession={handleSetSession} />
      )}
      {activeSession === "UtLiquidityOption" && (
        <UtLiquidityOptions handleSetSession={handleSetSession} />
      )}
      {activeSession === "UtDeployToken" && (
        <UtDeployToken handleSetSession={handleSetSession} />
      )}
      {activeSession === "UtReviewToken" && (
        <UtReviewToken handleSetSession={handleSetSession} />
      )}
    </section>
  );
}
