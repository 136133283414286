import React, { useEffect } from "react";
import NetworkData from "./NetworkData";

export default function NetworkSwitch({ network }) {
  const getChainId = () => {
    if (network === "Ethereum") {
      return "0x1";
    } else if (network === "BNB Smart Chain") {
      return "0x38";
    } else if (network === "Base") {
      return "0x2105";
    } else if (network === "Polygon") {
      return "0x89";
    } else if (network === "OP Mainnet") {
      return "0xa";
    } else if (network === "Arbitrum") {
      return "0xa4b1";
    } else if (network === "Avalanche") {
      return "0xa86a";
    } else if (network === "Sepolia") {
      return "0xaa36a7";
    } else if (network === "BNB Testnet") {
      return "0x61";
    } else if (network === "Base Sepolia") {
      return "0x14a34";
    }
  };

  useEffect(() => {
    const switchNetwork = async () => {
      const desiredChainId = getChainId();
      const netDetails = NetworkData(network);

      try {
        // Check if the desired network is already added
        const chainIds = await window.ethereum.request({
          method: "eth_chainId",
        });

        if (!chainIds.includes(desiredChainId)) {
          // If the desired network is not added, add it
          await window.ethereum.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainId: desiredChainId,
                chainName: netDetails.chainName,
                rpcUrls: [netDetails.rpcUrl],
                nativeCurrency: {
                  name: netDetails.currencyName,
                  symbol: netDetails.currencySymbol,
                  decimals: netDetails.currencyDecimals,
                },
                blockExplorerUrls: [netDetails.blockExplorerUrls],
              },
            ],
          });
        }

        // Switch to the desired network
        await window.ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: desiredChainId }],
        });
      } catch (err) {
        if (err.code === 4001) {
          console.log("Cancelled network switch operation");
        } else {
          console.error("Error switching network:", err);
        }
      }
    };

    if (network) {
      switchNetwork();
    }
    // eslint-disable-next-line
  }, [network]);

  return <></>;
}
