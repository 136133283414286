import React, { useState } from "react";
import "../ultimate-token/UltimateToken.css";
import TCTopContent from "../../utills/token-creation/TCTopContent/TCTopContent";
import DividendTokenInformation from "./sub-components/DividendTokenInformation";
import DividendTokenSupply from "./sub-components/DividendTokenSupply";
import DividendConfigureDividends from "./sub-components/DividendConfigureDividends";
import DividendConfigureFees from "./sub-components/DividendConfigureFees";
import DividendLiquidityOptions from "./sub-components/DividendLiquidityOptions";
import DividendDeployToken from "./sub-components/DividendDeployToken";
import DividendReviewToken from "./sub-components/DividendReivewToken";

export default function DividendToken() {
  const [activeSession, setActiveSesstion] = useState(
    "DividendTokenInformation"
  );
  const [barPercentage, setBarPercentage] = useState(0);

  const dtTopData = {
    heading: "Dividend Token",
    subHeading: `The best dividend based token, includes all standard features from the ERC20 standard with other utilities! You can customize your token with options such as rewards, dividends, including sending rewards in a specific token, requiring minimum balance and more! Supports fees as well.`,
    badgeData: [
      "ERC20",
      "Liquidity Fee",
      "Marketing Fee",
      "Dividends",
      "Rewards",
      "Minimum Balance",
      "Blacklist",
      "Exclusion",
    ],
  };

  const handleSetSession = (data) => {
    setActiveSesstion(data);

    if (data === "DividendTokenInformation") {
      setBarPercentage(0);
    } else if (data === "DividendTokenSupply") {
      setBarPercentage(20);
    } else if (data === "DividendConfigureDividends") {
      setBarPercentage(40);
    } else if (data === "DividendConfigureFees") {
      setBarPercentage(60);
    } else if (data === "DividendLiquidityOption") {
      setBarPercentage(80);
    } else if (data === "DividenDeployToken") {
      setBarPercentage(100);
    }
  };

  return (
      <section className="ultimate-token-container">
        <TCTopContent TCTopData={dtTopData} />

        {activeSession !== "DividendReviewToken" && (
          <div className="token-step-area">
            <div className="token-step-single">
              <h4
                className={`${
                  activeSession === "DividendTokenInformation"
                    ? "number-active"
                    : activeSession === "DividendTokenSupply" ||
                      activeSession === "DividendConfigureDividends" ||
                      activeSession === "DividendConfigureFees" ||
                      activeSession === "DividendLiquidityOption" ||
                      activeSession === "DividenDeployToken"
                    ? "number-visited"
                    : "number"
                }`}>
                1
              </h4>
              <p className="info">Token Information</p>
            </div>
            <div className="token-step-single">
              <h4
                className={`${
                  activeSession === "DividendTokenSupply"
                    ? "number-active"
                    : activeSession === "DividendConfigureDividends" ||
                      activeSession === "DividendConfigureFees" ||
                      activeSession === "DividendLiquidityOption" ||
                      activeSession === "DividenDeployToken"
                    ? "number-visited"
                    : "number"
                }`}>
                2
              </h4>
              <p className="info">Token Supply</p>
            </div>
            <div className="token-step-single">
              <h4
                className={`${
                  activeSession === "DividendConfigureDividends"
                    ? "number-active"
                    : activeSession === "DividendConfigureFees" ||
                      activeSession === "DividendLiquidityOption" ||
                      activeSession === "DividenDeployToken"
                    ? "number-visited"
                    : "number"
                }`}>
                3
              </h4>
              <p className="info">Configure Dividends</p>
            </div>
            <div className="token-step-single">
              <h4
                className={`${
                  activeSession === "DividendConfigureFees"
                    ? "number-active"
                    : activeSession === "DividendLiquidityOption" ||
                      activeSession === "DividenDeployToken"
                    ? "number-visited"
                    : "number"
                }`}>
                4
              </h4>
              <p className="info">Configure Fees</p>
            </div>
            <div className="token-step-single">
              <h4
                className={`${
                  activeSession === "DividendLiquidityOption"
                    ? "number-active"
                    : activeSession === "DividenDeployToken"
                    ? "number-visited"
                    : "number"
                }`}>
                5
              </h4>
              <p className="info">Liquidity Options</p>
            </div>
            <div className="token-step-single">
              <h4
                className={`${
                  activeSession === "DividenDeployToken"
                    ? "number-active"
                    : "number"
                }`}>
                6
              </h4>
              <p className="info">Deploy Token</p>
            </div>

            <div className="progress">
              <div
                className="progress-bar progress-bar-success"
                style={{ width: `${barPercentage}%` }}></div>
            </div>
          </div>
        )}

        {activeSession === "DividendTokenInformation" && (
          <DividendTokenInformation handleSetSession={handleSetSession} />
        )}
        {activeSession === "DividendTokenSupply" && (
          <DividendTokenSupply handleSetSession={handleSetSession} />
        )}
        {activeSession === "DividendConfigureDividends" && (
          <DividendConfigureDividends handleSetSession={handleSetSession} />
        )}
        {activeSession === "DividendConfigureFees" && (
          <DividendConfigureFees handleSetSession={handleSetSession} />
        )}
        {activeSession === "DividendLiquidityOption" && (
          <DividendLiquidityOptions handleSetSession={handleSetSession} />
        )}
        {activeSession === "DividenDeployToken" && (
          <DividendDeployToken handleSetSession={handleSetSession} />
        )}
        {activeSession === "DividendReviewToken" && (
          <DividendReviewToken handleSetSession={handleSetSession} />
        )}
      </section>
  );
}
