import React, { useState } from "react";
import "./Contact.css";
import axios from "axios";
import ContactPopup from "../../utills/Modal/ContactPopup";
import Footer from "../create-token/footer/Footer";
import BlueDexLinks from "../create-token/blue-dex-link/BlueDexLinks";
import APIURL from "../../utills/APIs/APIURL";
import SecureKyeGen from "../../utills/secureKeyGen/SecureKeyGen";
import { useAccount } from "wagmi";

const Contact = () => {
  const { address } = useAccount();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    category: "",
    message: "",
  });

  const [errors, setErrors] = useState({});
  const [showPopUp, setShowPopUp] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    // Validation logic
    const newErrors = { ...errors };
    const pattern = /^[a-zA-Z\s]+$/;

    switch (name) {
      case "name":
        newErrors.name = !value.trim()
          ? "Name is required"
          : !pattern.test(value)
          ? "Name must contain alphabets"
          : "";
        break;
      case "email":
        const validEmailRegex =
          /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

        newErrors.email =
          !value.trim() || !value.match(validEmailRegex)
            ? "Valid email is required"
            : "";
        break;
      case "subject":
        newErrors.subject = !value.trim() ? "Subject is required" : "";
        break;
      case "category":
        newErrors.category = value === "" ? "Category is required" : "";
        break;
      case "message":
        newErrors.message = !value.trim() ? "Message is required" : "";
        break;
      default:
        break;
    }

    setErrors(newErrors);
  };

  const validateForm = () => {
    let valid = true;
    const newErrors = {};

    if (!formData.name.trim()) {
      newErrors.name = "Name is required";
      valid = false;
    }

    const validEmailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (!formData.email.trim() || !formData.email.match(validEmailRegex)) {
      newErrors.email = "Valid email is required";
      valid = false;
    }

    if (!formData.subject.trim()) {
      newErrors.subject = "Subject is required";
      valid = false;
    }

    if (formData.category === "") {
      newErrors.category = "Category is required";
      valid = false;
    }

    if (!formData.message.trim()) {
      newErrors.message = "Message is required";
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    setLoading(true);

    try {
      const jsonData = JSON.stringify(formData);
      const response = await axios.post(`${APIURL.contact}`, jsonData, {
        headers: {
          Accept: "*/*",
          "Content-Type": "application/json",
          Authorization: `Bearer ${SecureKyeGen(
            address,
            process.env.REACT_APP_API_SECRET_KEY,
            Number(process.env.REACT_APP_API_SECRET_TIME)
          )}`,
        },
      });
      setShowPopUp(true);

      setLoading(false);
      setFormData({
        name: "",
        email: "",
        subject: "",
        category: "",
        message: "",
      });
    } catch (error) {
      console.error("Error submitting form:", error);
      setLoading(false);
    }
  };

  return (
    <>
      <div className="contact_main">
        <h1 className="contact_title">Contact</h1>
        <p className="contact_para">
          Reach out to us for help or for custom contract/token development!
        </p>
      </div>
      <div className="contact-form-container">
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="name">Your name</label>
            <input
              className="contact-input"
              type="text"
              id="name"
              name="name"
              placeholder="Enter your full name"
              value={formData.name}
              onChange={handleChange}
            />
            {errors.name && (
              <span className="error-message">{errors.name}</span>
            )}
          </div>

          <div className="form-group">
            <label htmlFor="email">Email address</label>
            <input
              className="contact-input"
              type="email"
              id="email"
              name="email"
              placeholder="Enter your Email address"
              value={formData.email}
              onChange={handleChange}
            />
            {errors.email && (
              <span className="error-message">{errors.email}</span>
            )}
          </div>

          <div className="form-group">
            <label htmlFor="subject">Subject</label>
            <input
              className="contact-input"
              type="text"
              id="subject"
              name="subject"
              placeholder="Enter a subject"
              value={formData.subject}
              onChange={handleChange}
            />
            {errors.subject && (
              <span className="error-message">{errors.subject}</span>
            )}
          </div>

          <div className="form-group">
            <label htmlFor="category">Category</label>
            <select
              className="select-option"
              id="category"
              name="category"
              placeholder="Select a category"
              value={formData.category}
              onChange={handleChange}>
              <option value="" className="option-select">
                Select Category
              </option>
              <option value="Partnership Enquiry">Partnership Enquiry</option>
              <option value="Press/Marketing">Press/Marketing</option>
              <option value="Support">Support</option>
            </select>
            {errors.category && (
              <span className="error-message">{errors.category}</span>
            )}
          </div>

          <div className="form-group">
            <label htmlFor="message">Message</label>
            <textarea
              id="message"
              name="message"
              className="contact-input"
              rows={6}
              placeholder="Enter your message"
              value={formData.message}
              onChange={handleChange}></textarea>
            {errors.message && (
              <span className="error-message">{errors.message}</span>
            )}
          </div>

          <div className="form-group">
            <button className="contact-form-button" type="submit">
              {loading ? `Loading...` : `Send Message`}
            </button>
          </div>
        </form>
      </div>

      {showPopUp && (
        <div className="popup_bg">
          {" "}
          <ContactPopup />{" "}
        </div>
      )}

      <BlueDexLinks />

      <Footer />
    </>
  );
};

export default Contact;
