import React, { createContext, useReducer, useContext } from 'react'
import NetworkSwitch from '../utills/networkDetails/NetworkSwitch';

const UltimateTokenContent = createContext();

const initialState = {
    tokenInfo: {
        tokenName: '',
        tokenSymbol: '',
        chainNetwork: '',
        customDecimal: false,
        changeDecimal: '18'
    },
    additional: {
        mintReport: false,
        burnReport: false,
        pauseReport: false,
        unlimitedReport: false,
        maxWalletLimit: false,
        maxTransLimit: false,
        blackList: false,
        buySellFees: false,
        feeOnTransfer: false,
    },
    tokenSupply: {
        initialSupply: '1000000',
        maxSupply: '1000000'
    },
    liquidityOption: {
        addLiquidity: false,
        tokenAmount: 50,
        ethAmount: '',
        launchMarketCap: '',
        launchTokenPrice: '',
        dexExchange: '',
        liquidityOwnership: 'None'
    },
    configureFees: {
        liqFeeBuy: 0,
        liqFeeSell: 0,
        liqExchange: '',
        markOpFeeBuy: 0,
        markOpFeeSell: 0,
        markOpWallet: '',
        burnFeeBuy: 0,
        burnFeeSell: 0,
    },
    configureDividends: {
        dividenFees: 0,
        rewardToken: '0x0000000000000000000000000000000000000000',
        minBalForDividendsToggle: false,
        minBalForDividends: 0,
    },
    contractAddress: {
        ctAddress: '',
    },
    fees: {
        baseFee: '',
        burnFee: ''
    }
}
const handleReducer = (state, action) => {
    return {
        ...state,
        [action.section]: {
            ...state[action.section],
            ...action.payload,
        },
    };
}
export const UltimateTokenProvider = ({ children }) => {
    const [state, dispatch] = useReducer(handleReducer, initialState);

    return (
        <UltimateTokenContent.Provider value={{ state, dispatch }}>
            {children}
            <NetworkSwitch network={state.tokenInfo.chainNetwork} />
        </UltimateTokenContent.Provider>);
};

export const useUltimateTokenContext = () => {
    return useContext(UltimateTokenContent);
}
