import React, { useState } from "react";

export default function FAQContent({ faqContent }) {
  const [expandedItems, setExpandedItems] = useState(
    Array(faqContent.length).fill(false)
  );

  const handleExpand = (index) => {
    const newExpandedItems = [...expandedItems];
    newExpandedItems[index] = !newExpandedItems[index];
    setExpandedItems(newExpandedItems);
  };

  return (
    <section className="faq-main-section">
      {faqContent.map((value, index) => (
        <div
          className={
            expandedItems[index]
              ? "faq-content-cont-expand"
              : "faq-content-cont"
          }
          key={index}
          onClick={() => handleExpand(index)}>
          <div className="faq-head-cont">
            <div>{value.heading}</div>

            <div
              className={
                expandedItems[index]
                  ? "faq-arrow-cont-expand"
                  : "faq-arrow-cont"
              }>
              <span
                className={
                  expandedItems[index] ? "faq-arrow-expand" : "faq-arrow"
                }></span>
            </div>
          </div>

          {expandedItems[index] && (
            <div className="faq-content">{value.content}</div>
          )}
        </div>
      ))}
    </section>
  );
}
