import React from "react";
import "../../ultimate-token/sub-components/SubComponents.css";
import Toggle from "../../../utills/Toggle/Toggle.js";
import { Tooltip } from "react-tooltip";
import { useDeflationaryTokenContext } from "../../../context/DeflationaryContext.js";

export default function DtAdditionalFeatures({ handleSetSession }) {
  const { state, dispatch } = useDeflationaryTokenContext();

  const handleInputChage = (field, value) => {
    dispatch({
      type: "UPDATE_FORM_DATA",
      section: "additional",
      payload: { [field]: value },
    });
  };

  const setToggleValue = (id, data) => {
    handleInputChage(id, data);
  };

  const ToggleMapData = {
    maxWalletLimit: {
      id: "maxWalletLimit",
      text1: "Enable Max Wallet Limit",
      text2:
        "Limits the maximum number of tokens that can be held by a single wallet.",
      isTrue: state.additional.maxWalletLimit,
      tooltipText: `Enabling the Max Wallet Limit feature allows you to set a maximum threshold for the number of tokens that can be held in a single wallet address. This helps prevent large token holders, also known as whales, from exerting excessive control over the token's supply and market dynamics.`,
    },
    maxTransLimit: {
      id: "maxTransLimit",
      text1: "Enable Maximum Transaction Limit",
      text2:
        "Limits the maximum number of tokens that can be transferred in a single transaction.",
      isTrue: state.additional.maxTransLimit,
      tooltipText: `Enabling the Max Transaction Limit feature allows you to set a maximum limit on the number of tokens that can be transferred in a single transaction. This helps mitigate the risk of large-scale token movements that could disrupt the token's price stability or market liquidity.`,
    },
    // blackList: {
    //   text1: "Enable Blacklist",
    //   text2:
    //     "Allows the owner to blacklist accounts to disable transfers and token use.",
    //   isTrue: state.additional.blackList,
    // },
    buySellFees: {
      id: "buySellFees",
      text1: "Separate Transaction Taxes (Buy, Sell and Burn)",
      text2:
        "Allows you to set different fees for buying and selling the token.",
      isTrue: state.additional.buySellFees,
      tooltipText: `Enabling the Separate Buy & Sell Fees feature empowers you with advanced control over transaction taxes, allowing you to set distinct fees for both buying and selling the token. Beyond basic transaction fees, this feature also extends to burn taxes. By customising fees for each type of transaction, you can finely tune the tokenomics to optimise liquidity, incentivise desired behaviours, and manage supply dynamics effectively. This granular control over tax parameters ensures flexibility in tailoring the token's economic model to align with your project's specific objectives and market conditions.`,
    },
    feeOnTransfer: {
      id: "feeOnTransfer",
      text1: "Apply fees on transfers between wallets",
      text2:
        "Apply fees on transfers between wallets. Applies buy fees for Transfers.",
      isTrue: state.additional.feeOnTransfer,
      tooltipText: `Enabling the Apply Fees on Transfers feature allows you to apply transaction fees on token transfers between wallets. These fees are deducted from the transferred token amount and can be used to generate additional revenue or redistribute value within the token ecosystem. By applying buy fees for transfers, you can incentivise holders to engage in more token transactions, thereby enhancing token liquidity and utility.`,
    },
  };

  return (
    <section className="token-create-main-cont">
      <div>
        <div className="tc-additional-feature-heading-cont">
          <div>Additional Features</div>
          <div>Enable additional features for your token.</div>
        </div>

        {Object.entries(ToggleMapData).map(([key, value], index) => (
          <div className={`tc-toggle-cont-additional-feature`} key={index}>
            <div style={{ width: "82%" }}>
              <div
                className="input-heading-cont"
                style={{
                  display: "inline-block",
                }}>
                {value.text1}{" "}
                <div
                  style={{
                    display: "inline-block",
                    position: "relative",
                    top: "-2px",
                  }}>
                  <span id={value.id} className="tooltip-design">
                    i
                  </span>
                </div>
              </div>
              <div>{value.text2}</div>
            </div>

            <Toggle
              setToggleValue={setToggleValue}
              value={value.isTrue}
              keyValue={key}
            />

            <Tooltip
              anchorSelect={`#${value.id}`}
              className="styletooltip"
              style={{
                backgroundColor: "#12141a",
                borderRadius: "12px",
                zIndex: "11111",
                width: "350px",
                textAlign: "center",
              }}>
              {value.tooltipText}
            </Tooltip>
          </div>
        ))}
      </div>

      <div className="nxt-prev-btn-cont">
        <button
          className="grey-btn"
          onClick={() => handleSetSession("DtTokenInformation")}>
          Previous
        </button>
        <button
          className="gradient-btn"
          onClick={() => handleSetSession("DtTokenSupply")}>
          Next
        </button>
      </div>
    </section>
  );
}
