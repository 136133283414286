import React from "react";
import "../../ultimate-token/sub-components/SubComponents.css";
import WalletConnectBtn from "../../../utills/wallet/WalletConnectBtn";
import { useUltimateTokenContext } from "../../../context/UltimateContext";

export default function DividendDeployToken({ handleSetSession }) {
  const { state } = useUltimateTokenContext();
  return (
    <section className="token-create-main-cont">
      <div className="box-bg">
        <div className="token-create-heading-cont deploy-token-border-none">
          <div>Deploy Token</div>
          <div>Ready to deploy?</div>
        </div>

        <div className="tc-deploy-token deploy-token-border-none">
          <div className="tcdt-fee-text">
            {state.liquidityOption.ethAmount && (
              <>
                <div>ETH for Initial Liquidity</div>
                <div>{state.liquidityOption.ethAmount} ETH</div>
              </>
            )}
          </div>

          <div className="tcdt-fee-text">
            <div>ETH fee for token deployment</div>
            <div>0.1 ETH</div>
          </div>

          <div className="token-offer-btn tcdt-total-fee-text">
            {state.liquidityOption.ethAmount
              ? `${(Number(state.liquidityOption.ethAmount) + 0.1).toFixed(
                  6
                )} ETH`
              : `0.1 ETH`}
          </div>
        </div>

        <div className="tc-deploy-token-wallet-cnt-btn connect-wallet-bg-res-bg">
          <WalletConnectBtn btnFor={"NotHomePage"} />
        </div>
      </div>

      <div className="nxt-prev-btn-cont">
        <button
          className="grey-btn"
          onClick={() => handleSetSession("DividendLiquidityOption")}>
          Previous
        </button>
        <button
          className="gradient-btn"
          onClick={() => handleSetSession("DividendReviewToken")}>
          Next
        </button>
      </div>
    </section>
  );
}
