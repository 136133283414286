import React, { useEffect, useState } from "react";
import "../../ultimate-token/sub-components/SubComponents.css";
import WalletConnectBtn from "../../../utills/wallet/WalletConnectBtn";
import { useAccount, useBalance, useChainId } from "wagmi";
import { useDeflationaryTokenContext } from "../../../context/DeflationaryContext";
import { Tooltip } from "react-tooltip";
import APIURL from "../../../utills/APIs/APIURL";
import axios from "axios";

export default function DtDeployTOken({ handleSetSession }) {
  const { state, dispatch } = useDeflationaryTokenContext();

  const [insufficientBalError, setInsufficientBalError] = useState("");

  const [inputError, setInputError] = useState("");
  const [feeFetchError, setFeeFetchError] = useState("");

  const chainId = useChainId();

  const { address } = useAccount();

  const { data: walletBalance } = useBalance({ address: address });

  const handleInputChage = (field, value) => {
    dispatch({
      type: "UPDATE_FORM_DATA",
      section: "fees",
      payload: { [field]: value },
    });
  };

  const handleNextComponent = () => {
    let isValid = true;

    if (!address || (chainId !== 84532 && chainId !== 8453)) {
      setInputError("Please connect a wallet with available networks");
      isValid = false;
    } else {
      setInputError("");
    }

    if (
      Number(walletBalance?.formatted) <=
      (state.liquidityOption.ethAmount
        ? `${(
            Number(state.liquidityOption.ethAmount) +
            (state.additional.burnReport
              ? Number(
                  (
                    Number(state.fees.baseFee) + Number(state.fees.burnFee)
                  ).toFixed("3")
                )
              : Number(state.fees.baseFee))
          ).toFixed(6)}`
        : state.additional.burnReport
        ? (Number(state.fees.baseFee) + Number(state.fees.burnFee)).toFixed("3")
        : state.fees.baseFee)
    ) {
      setInsufficientBalError("Your wallet doesn't have sufficient balance");

      isValid = false;
    } else {
      setInsufficientBalError("");
    }

    if (!state.fees.baseFee) {
      setFeeFetchError("Please wait till price fetch!");
      isValid = false;
    } else {
      setFeeFetchError("");
    }

    if (isValid) {
      handleSetSession("DtReviewToken");
    }
  };

  useEffect(() => {
    const getTokenFees = async () => {
      try {
        const response = await axios.get(
          `${APIURL.fetchDeflationaryTOkenPrice}`
        );

        handleInputChage("baseFee", response.data.data.basePrice);
        handleInputChage("buySellFees", response.data.data.buySellFees);
        handleInputChage("feesOnTransfers", response.data.data.feesOnTransfers);
        handleInputChage(
          "maxTransactionLimit",
          response.data.data.maxTransactionLimit
        );
        handleInputChage("maxWalletLimit", response.data.data.maxWalletLimit);
      } catch (error) {
        console.error(error);
      }
    };

    getTokenFees();
  }, []);

  const handleSetTotalPrice = () => {
    const totalFees =
      Number(state.fees.baseFee) +
      (state.additional.buySellFees ? Number(state.fees.buySellFees) : 0) +
      (state.additional.feeOnTransfer
        ? Number(state.fees.feesOnTransfers)
        : 0) +
      (state.additional.maxTransLimit
        ? Number(state.fees.maxTransactionLimit)
        : 0) +
      (state.additional.maxWalletLimit ? Number(state.fees.maxWalletLimit) : 0);

    handleInputChage("totalFee", totalFees);
  };

  useEffect(() => {
    handleSetTotalPrice();
  }, [state.fees.baseFee]);

  return (
    <section className="token-create-main-cont">
      <div className="box-bg">
        <div className="token-create-heading-cont">
          <div>Deploy Token</div>
          <div>Ready to deploy?</div>
        </div>

        <div className="tc-deploy-token deploy-token-border-none">
          <div className="input-heading-cont">
            <div className="summary-heading">Summary</div>
            <span id="utrtTokenOwnership" className="tooltip-design">
              i
            </span>
            <Tooltip
              anchorSelect="#utrtTokenOwnership"
              className="styletooltip"
              style={{
                backgroundColor: "#12141a",
                borderRadius: "12px",
                zIndex: "11111",
                width: "500px",
                textAlign: "center",
                fontSize: "15px",
                fontWeight: "400",
              }}>
              This summary displays the total ETH required for deploying your
              token and adding initial liquidity to the pool. The "ETH for
              Initial Liquidity" represents the amount of ETH you've allocated
              to fund the liquidity pool, while the "ETH fee for token
              deployment" is the fee charged for deploying your token on the
              blockchain. The "Total ETH required" combines these amounts to
              give you the complete deployment cost. <br />
              <div style={{ marginTop: "15px" }}>
                Please ensure you have sufficient ETH in your wallet to cover
                these fees before proceeding with deployment.
              </div>
            </Tooltip>
          </div>

          <div className="tcdt-fee-details-cont">
            <div className="tcdt-fee-text">
              {state.liquidityOption.addLiquidity && (
                <>
                  <div>ETH for Initial Liquidity</div>
                  <div>
                    {state.liquidityOption.ethAmount
                      ? state.liquidityOption.ethAmount
                      : 0}{" "}
                    ETH
                  </div>
                </>
              )}
            </div>

            <div className="tcdt-fee-text">
              <div>ETH fee for token deployment</div>
              <div>{Number(state.fees.totalFee).toFixed(6)} ETH</div>
            </div>

            <div className="token-offer-btn tcdt-total-fee-text">
              {state.liquidityOption.ethAmount
                ? `${(
                    Number(state.liquidityOption.ethAmount) +
                    Number(state.fees.totalFee)
                  ).toFixed(6)} ETH`
                : `${Number(state.fees.totalFee).toFixed(6)} ETH`}
            </div>
          </div>
        </div>

        <div className="tc-deploy-token-wallet-cnt-btn">
          <WalletConnectBtn btnFor={"NotHomePage"} />
          {inputError && <p className="input_error">{inputError}</p>}

          {insufficientBalError && (
            <p className="input_error">{insufficientBalError}</p>
          )}

          {feeFetchError && <p className="input_error">{feeFetchError}</p>}
        </div>
      </div>

      <div className="nxt-prev-btn-cont">
        <button
          className="grey-btn"
          onClick={() => handleSetSession("DtLiquidityOption")}>
          Previous
        </button>
        <button className="gradient-btn" onClick={handleNextComponent}>
          Next
        </button>
      </div>
    </section>
  );
}
