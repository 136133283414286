import React from "react";
import "./TokenCreator.css";
import TokenCreatorBadge from "../../../utills/TokenCreatorBadge";
import { useNavigate } from "react-router-dom";

export default function TokenCreator({ tokenType }) {
  const navigate = useNavigate();

  const TokenCreateData = {
    ultimate: {
      heading: "Core Token",
      type: "Most Popular",
      content: `The Core Token is built on the ERC20 standard and offers essential features plus added flexibility for customisation. It's the perfect starting point for anyone looking to create their token without needing advanced knowledge, providing a straightforward path to ownership and supply management.`,
      badge: [
        "ERC20",
        "Supply Limits",
        "Ownership",
        "Transfer Token",
        // "Minting",
        "Burning",
        // "Pausable",
      ],
    },
    deflationary: {
      heading: "Deflationary Token",
      type: "",
      content: `The Deflationary Token merges the ERC20 standard's reliability with enhanced utilities for a dynamic market presence. Customise your token to fit your strategy, setting limits on wallet holdings, transactions, and integrating buy and sell fees among other options. It's designed for those who want to actively manage their token's economy and encourage a healthier circulation.`,
      badge: [
        "ERC20",
        "Supply Limits",
        "Deflationary",
        // "Blacklist",
        "Burn Fee",
        "Liquidity Fee",
        "Marketing Fee",
        "Wallet Limits",
      ],
    },
    dividend: {
      heading: "Dividend Token",
      type: "Coming Soon",
      content: `The Dividend Token combines ERC20 basics with rewarding features. Customise it to offer rewards and dividends, even in specific tokens, and set a minimum balance for participation. Ideal for incentivising long-term holding, it also supports fee integration, making it a practical choice for building community value.`,
      badge: [
        "ERC20",
        "Exclusion",
        "Blacklist",
        "Marketing Fee",
        "Minimum Balance",
        "Liquidity Fee",
        "Dividends",
      ],
    },
    // dn404: {
    //   heading: "DN404 Divisible NFT",
    //   content:
    //     "DN404 is an implementation of a co-joined ERC20 and ERC721 pair created by the Solady + Gaslite team. It is a more gas efficient take on the ERC404 format.",
    //   badge: ["ERC20", "Max Supply", "ERC721", "ERC20", "Gas Optimized"],
    // },
  };

  const handleTokenData = (value) => {
    if (value === "Core") {
      return TokenCreateData.ultimate;
    } else if (value === "Deflationary") {
      return TokenCreateData.deflationary;
    } else if (value === "Dividend") {
      return TokenCreateData.dividend;
    }
    // else if (value === "DN404 Divisible") {
    //   return TokenCreateData.dn404;
    // }
  };

  const handleNavigate = (value) => {
    if (value === "Core") {
      navigate("core-token");
    } else if (value === "Deflationary") {
      navigate("deflationary-token");
    }
    // else if (value === "Dividend") {
    //   navigate("dividend-token");
    // }
    // else if (value === "DN404 Divisible") {
    //   navigate("dn404-token");
    // }
  };

  return (
    <div
      className="token-create-cont"
      onClick={() => handleNavigate(tokenType)}>
      <div className="tc-first-row">
        <div className="tcfr-text">{handleTokenData(tokenType).heading}</div>
        {handleTokenData(tokenType)?.type && (
          <div className="tc-badge-cont">
            <div className="tc-triangle-design"></div>{" "}
            <div className="tc-badge">{handleTokenData(tokenType)?.type}</div>
          </div>
        )}
      </div>

      <div className="tc-sec-row">{handleTokenData(tokenType).content}</div>
      <div>
        {handleTokenData(tokenType).badge.map((value, key) => (
          <TokenCreatorBadge
            key={key}
            badgeValue={value}
            badgeFor={"tokenBox"}
          />
        ))}
      </div>
    </div>
  );
}
