import React from "react";
import "./TermsCondtions.css";
import Footer from "../create-token/footer/Footer";
import BlueDexLinks from "../create-token/blue-dex-link/BlueDexLinks";

export default function TermsCondition() {
  const termsConditionData = [
    {
      heading: "Acceptance of Terms:",
      content:
        "Your use of Blueprint constitutes acceptance of these Terms. If you do not agree to these Terms, you must refrain from using Blueprint.",
    },
    {
      heading: "Service Availability and Quality:",
      content: `Blueprint is provided "as is," without warranties or conditions of any kind, express or implied. Bluecore disclaims all warranties, including but not limited to merchantability, fitness for a particular purpose, and non-infringement. You use Blueprint at your own risk.`,
    },
    {
      heading: "Copyright and Trademark:",
      content:
        "Bluecore does not claim ownership of the source code for any deployed contracts on Blueprint. You are solely responsible for any use or redistribution of the created assets. Bluecore grants you a limited, non-exclusive, non-transferable licence to use Blueprint for creating tokens. This licence does not extend to any other purpose or third-party use.",
    },
    {
      heading: "Community Guidelines:",
      content:
        "Users are expected to adhere to community guidelines, including refraining from deploying tokens that are disrespectful, aggressive, or otherwise abusive. Any violations may result in suspension or termination of access to Blueprint.",
    },
    {
      heading: "Limitation of Liability and Indemnity:",
      content:
        "Bluecore shall not be liable for any direct, indirect, incidental, special, consequential, or exemplary damages arising from the use of Blueprint or any created assets. You agree to indemnify and hold Bluecore harmless from any claims, losses, damages, liabilities, costs, and expenses arising out of or relating to your use of Blueprint.",
    },
    {
      heading: "Miscellaneous:",
      content: {
        content1:
          "Force Majeure. Bluecore shall not be liable for any failure or delay in performance caused by circumstances beyond its control, including but not limited to acts of God, natural disasters, terrorism, riots, or war.",
        content2:
          "Severability. If any provision of these Terms is found to be invalid, illegal, or unenforceable, the remaining provisions will remain in full force and effect.",
        content3:
          "Survival. Sections relating to warranty disclaimers, limitation of liability, and indemnification shall survive termination or expiration of these Terms.",
        content4:
          "By using Blueprint, you acknowledge that you have read, understood, and agree to be bound by these Terms.",
      },
    },
  ];

  return (
    <section className="termsCondition-conainer">
      <div className="termsCond-top-sec">
        <div>Terms & Conditions</div>
        <div>
          These Terms and Conditions ("Terms") govern your use of Blueprint,
          including the website, token creation platform, and any associated
          services provided by Bluecore Labs ("Bluecore," "we," or "us"). By
          accessing or using Blueprint, you agree to comply with these Terms. If
          you do not agree with any part of these Terms, you may not use
          Blueprint.
        </div>
      </div>

      {termsConditionData.map((value, index) => (
        <div key={index} className="tc-content-sec">
          <div>{value.heading}</div>
          {value.content.content1 ? (
            <>
              <div className="miscelleanous-text">{value.content.content1}</div>
              <div className="miscelleanous-text">{value.content.content2}</div>
              <div className="miscelleanous-text">{value.content.content3}</div>
              <div className="miscelleanous-text">{value.content.content4}</div>
            </>
          ) : (
            <div>{value.content}</div>
          )}
        </div>
      ))}

      <BlueDexLinks />

      <Footer />
    </section>
  );
}
