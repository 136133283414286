import React from "react";
import "./SubComponents.css";

function MyInput({ value, min, max, param, handleChange, inputFor }) {
  const generateBackground = () => {
    if (value === min) {
      return;
    }

    let percentage = ((value - min) / (max - min)) * 100;
    return {
      background: `linear-gradient(to right, #66AAFE, #7027C8 ${percentage}%, #FFFFFF4D ${percentage}%, #FFFFFF4D 100%)`,
    };
  };

  const handleChangeRange = (event) => {
    handleChange(param, event.target.value);
  };

  return (
    <>
      {inputFor === "sell" ? (
        <div className="range_slider">
          <input
            type="range"
            min={min}
            max={max}
            step="1"
            value={value}
            onChange={handleChangeRange}
            style={generateBackground()}
          />
        </div>
      ) : (
        <div className="range_slider">
          <input
            type="range"
            min={min}
            max={max}
            step="1"
            value={value}
            onChange={handleChangeRange}
            style={generateBackground()}
          />
        </div>
      )}
    </>
  );
}

export default MyInput;
