import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ethereumClient, projectId, wagmiConfig } from "./utills/wallet/WalletConfig";
import { Web3Modal } from "@web3modal/react";
import { WagmiConfig } from "wagmi";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <WagmiConfig config={wagmiConfig}>
      <App />
    </WagmiConfig>

    {(() => {
      try {
        return (
          <Web3Modal
            projectId={projectId}
            theme="dark"
            accentColor="default"
            ethereumClient={ethereumClient}
          />
        );
      } catch (error) {
        console.error("Error initializing Web3Modal:", error);
        return null;
      }
    })()}
  </React.StrictMode>
);

reportWebVitals();
