import React from "react";
import "../../ultimate-token/sub-components/SubComponents.css";
import Toggle from "../../../utills/Toggle/Toggle";
import { useUltimateTokenContext } from "../../../context/UltimateContext";
import MyInput from "../../ultimate-token/sub-components/MyInput";
import { useNavigate } from "react-router-dom";

export default function DividendConfigureDividends({ handleSetSession }) {
  const navigate = useNavigate();

  const { state, dispatch } = useUltimateTokenContext();

  const handleInputChage = (field, value) => {
    dispatch({
      type: "UPDATE_FORM_DATA",
      section: "configureDividends",
      payload: { [field]: value },
    });
  };

  const handleChange = (field, value) => {
    handleInputChage(field, value);
  };

  const setToggleValue = (field, data) => {
    handleInputChage(field, data);
  };

  return (
    <section className="token-create-main-cont">
      <div className="box-bg">
        <div className="token-create-heading-cont">
          <div>Configure Dividends</div>
          <div>Let's configure dividends for your token.</div>
        </div>

        <div className="tc-config-dividend">
          <div>
            <div>Dividend Fees</div>
            <div>
              The percentage of the transaction that will be used to send
              dividends. Maximum of 10%.
            </div>
            <div className="tc-range-cont">
              <MyInput
                min={0}
                max={10}
                value={state.configureDividends.dividenFees}
                param={"dividenFees"}
                handleChange={handleChange}
              />
              <span className="tc-decimal-count">
                {state.configureDividends.dividenFees}
              </span>
            </div>
          </div>

          <div>
            <div>Reward Token</div>
            <div>
              Dividends will be sent as this token, it must be or have a pool
              with WETH.
            </div>
            <input
              type="text"
              value={state.configureDividends.rewardToken}
              onChange={(e) => handleInputChage("rewardToken", e.target.value)}
              name="token-name"
              placeholder="Awesome Token"
            />
          </div>
        </div>

        <div className="tc-toggle-cont config-divident-toggle">
          <div>
            <div>Require Minimum Balance for Dividends</div>
            <div>Require a minimum balance of tokens to be held in order to be eligible for dividends. Min 0% and max 5% of total supply.</div>
          </div>
          <Toggle 
            value={state.configureDividends.minBalForDividendsToggle}
            setToggleValue={setToggleValue}
            keyValue={"minBalForDividendsToggle"}
          />
        </div>
        {state.configureDividends.minBalForDividendsToggle && (
          <div className="tc-range-cont">
            <MyInput
              min={0}
              max={5}
              value={state.configureDividends.minBalForDividends}
              param={"minBalForDividends"}
              handleChange={handleChange}
            />
            <span className="tc-decimal-count">
              {state.configureDividends.minBalForDividends}
            </span>
          </div>
        )}
      </div>

      <div className="nxt-prev-btn-cont">
        <button
          className="grey-btn"
          onClick={() => handleSetSession("DividendTokenSupply")}>
          Previous
        </button>
        <button
          className="gradient-btn"
          onClick={() => handleSetSession("DividendConfigureFees")}>
          Next
        </button>
      </div>
    </section>
  );
}
