import React from "react";
import "../../ultimate-token/sub-components/SubComponents.css";
import { Tooltip } from "react-tooltip";
import { useDeflationaryTokenContext } from "../../../context/DeflationaryContext";

export default function DtTokenSupply({ handleSetSession }) {
  const { state, dispatch } = useDeflationaryTokenContext();

  const handleChangeInput = (field, value) => {
    dispatch({
      type: "UPDATE_FORM_DATA",
      section: "tokenSupply",
      payload: { [field]: value },
    });
  };

  return (
    <section className="token-create-main-cont">
      <div className="box-bg">
        <div className="token-create-heading-cont">
          <div>Token Supply</div>
          <div>Let’s set the supply for your token</div>
        </div>

        <div className="token-supply-input-cont-col2">
          <div className="tsicc2-input">
            <div className="input-heading-cont">
              Initial Supply{" "}
              <span id="dtInitialSupply" className="tooltip-design">
                i
              </span>
            </div>
            <input
              type="number"
              onChange={(e) => {
                const value = parseInt(e.target.value);
                if (!isNaN(value)) {
                  const newValue = Math.max(
                    10000,
                    Math.min(value, 10000000000000)
                  );
                  handleChangeInput("initialSupply", newValue);
                }
              }}
              value={state.tokenSupply.initialSupply}
              min={10000}
              max={10000000000000}
              onWheel={(e) => e.target.blur()}
              name="dtTokenSupply"
            />

            <Tooltip
              anchorSelect="#dtInitialSupply"
              className="styletooltip"
              style={{
                backgroundColor: "#12141a",
                borderRadius: "12px",
                zIndex: "11111",
                width: "350px",
                textAlign: "center",
              }}>
              This represents the number of tokens initially available when your
              token launches. It's the starting point for your token's
              circulation within your ecosystem. You might consider factors like
              market demand, token utility, and project goals when determining
              the initial supply.
            </Tooltip>
          </div>
        </div>
      </div>

      <div className="nxt-prev-btn-cont">
        <button
          className="grey-btn"
          onClick={() => handleSetSession("DtAdditionalFeature")}>
          Previous
        </button>
        <button
          className="gradient-btn"
          onClick={() => handleSetSession("DtConfigureFee")}>
          Next
        </button>
      </div>
    </section>
  );
}
