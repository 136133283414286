import React from "react";
import "./CreateToken.css";
import WalletConnectBtn from "../../utills/wallet/WalletConnectBtn";
import TokenCreator from "./token-creator/TokenCreator";
import TokenFeatures from "./token-features/TokenFeatures";
import BlueDexLinks from "./blue-dex-link/BlueDexLinks";
import Footer from "./footer/Footer";

export default function CreateToken() {
  const tokenType = ["Core", "Deflationary", "Dividend"];

  return (
    <section className="create-token-container">
      <div className="ct-top-cont">
        <div>Create Token</div>
        <p>
          Own your token in a matter of minutes. Choose from our diverse token
          selection.
        </p>
        <WalletConnectBtn btnFor={"homePage"} />
      </div>

      <div className="ct-ctt">Choose Token Type</div>
      <div className="ct-box">
        {tokenType.map((value, key) => (
          <TokenCreator key={key} tokenType={value} />
        ))}
      </div>

      <div className="ct-sec-container">
        <div>Functionality at its core.</div>
        <div>
          Every token in our suite comes equipped with a comprehensive array of
          standard features, at no additional cost. This ensures that,
          regardless of the option you select, you're starting with the finest
          tools and functionalities available, designed to meet your needs right
          out of the gate.
        </div>
      </div>

      <TokenFeatures />

      <BlueDexLinks />

      <Footer />
    </section>
  );
}
