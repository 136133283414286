import React, { createContext, useReducer, useContext } from "react";
import NetworkSwitch from "../utills/networkDetails/NetworkSwitch";

const DeflationaryTokenContent = createContext();

const initialState = {
  tokenInfo: {
    tokenName: "",
    tokenSymbol: "",
    chainNetwork: "",
    customDecimal: false,
    changeDecimal: "18",
  },
  additional: {
    mintReport: false,
    burnReport: false,
    pauseReport: false,
    unlimitedReport: false,
    maxWalletLimit: false,
    maxTransLimit: false,
    blackList: false,
    buySellFees: false,
    feeOnTransfer: false,
  },
  tokenSupply: {
    initialSupply: "1000000",
    maxSupply: "1000000",
  },
  liquidityOption: {
    addLiquidity: false,
    tokenAmount: 50,
    ethAmount: "",
    launchMarketCap: "",
    launchTokenPrice: "",
    dexExchange: "",
    liquidityOwnership: "None",
  },
  configureFees: {
    liqFeeBuy: 0,
    liqFeeSell: 0,
    liqExchange: "",
    markOpFeeBuy: 0,
    markOpFeeSell: 0,
    markOpWallet: "",
    burnFeeBuy: 0,
    burnFeeSell: 0,
  },
  configureDividends: {
    dividenFees: 0,
    rewardToken: "0x0000000000000000000000000000000000000000",
    minBalForDividendsToggle: false,
    minBalForDividends: 0,
  },
  contractAddress: {
    ctAddress: "",
  },
  fees: {
    baseFee: "",
    buySellFees: "",
    feesOnTransfers: "",
    maxTransactionLimit: "",
    maxWalletLimit: "",
    totalFee: "",
  },
};
const handleReducer = (state, action) => {
  return {
    ...state,
    [action.section]: {
      ...state[action.section],
      ...action.payload,
    },
  };
};
export const DeflationaryTokenProvider = ({ children }) => {
  const [state, dispatch] = useReducer(handleReducer, initialState);

  return (
    <DeflationaryTokenContent.Provider value={{ state, dispatch }}>
      {children}
      <NetworkSwitch network={state.tokenInfo.chainNetwork} />
    </DeflationaryTokenContent.Provider>
  );
};

export const useDeflationaryTokenContext = () => {
  return useContext(DeflationaryTokenContent);
};
