import React, { useState, useRef, useEffect } from "react";
import "./Navbar.css";
import { Link } from "react-router-dom";
import BlueCoreLogo from "../../assets/bluecore_logo.png";
import WalletConnectBtn from "../../utills/wallet/WalletConnectBtn";
import NetworkDropdown from "../../utills/networkDropdown/NetworkDropdown";
import baseIcon from "../../assets/network_iocns/base_network_icon.png";
import NotSupportIcon from "../../assets/network_iocns/not_supported_network_icon.png";
import { useAccount, useChainId } from "wagmi";
import { Tooltip } from "react-tooltip";

export default function Navbar() {
  const [navExpand, setNavExpand] = useState(false);
  const [networkListOpenStat, setNetworkListOpenStat] = useState(false);
  const dropdownRef = useRef(null);
  const { address } = useAccount();
  const chainId = useChainId();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setNetworkListOpenStat(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleMouseEnter = () => {
    setNavExpand(true);
  };

  const handleMouseLeave = () => {
    setNavExpand(false);
  };

  return (
    <>
      <div className="nav-wrapper">
        <nav className="nav-container">
          <Link to="/create-token" className="nav-logo-header">
            <img src={BlueCoreLogo} alt="" height={50} width={50} />
            <p className="nav-header">BLUEPRINT</p>
          </Link>

          <ul className="nav-list">
            <li>
              <Link className="nav-link" to="/create-token">
                Home
              </Link>
            </li>

            <li
              className="nav-expnadable-link"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}>
              <Link className="nav-link" to="/create-token">
                Token Creator
                <div
                  className={`${
                    navExpand ? "nav-shrink-arrow" : "nav-expand-arrow"
                  }`}></div>
              </Link>

              {navExpand && (
                <div className="nav-expand-list">
                  <ul>
                    <li>
                      <Link to={"/create-token/core-token"}>Core Token</Link>
                    </li>
                    <li>
                      <Link to={"/create-token/deflationary-token"}>
                        Deflationary Token
                      </Link>
                    </li>
                    <li>
                      <Link to={"#"}>Dividend Token</Link>
                    </li>
                  </ul>
                </div>
              )}
            </li>
            <li>
              <Link className="nav-link" to="/faqs">
                FAQs
              </Link>
            </li>
            <li>
              <Link className="nav-link" to="/contact">
                Contact
              </Link>
            </li>
          </ul>

          {/* <button type="button" className="nav-btn">
            Create Token For Free!
          </button> */}

          <div className="nav-right-btn-cont">
            <div ref={dropdownRef}>
              <div
                id="unsupportNetwork"
                className={`network-toggle ${
                  networkListOpenStat && "network-toggle-active"
                }`}
                onClick={() => setNetworkListOpenStat(!networkListOpenStat)}>
                <img
                  src={address && chainId !== 84532 && chainId !== 8453 ? NotSupportIcon : baseIcon}
                  alt=""
                  height={20}
                  width={20}
                />

                {address && chainId !== 84532 && chainId !== 8453 && (
                  <Tooltip
                    anchorSelect="#unsupportNetwork"
                    className="styletooltip"
                    style={{
                      backgroundColor: "#12141a",
                      borderRadius: "12px",
                      zIndex: "11111",
                      width: "300px",
                      textAlign: "center",
                      fontSize: "13px",
                      fontWeight: "400",
                    }}>
                    Your wallet's current network is unsupported.
                  </Tooltip>
                )}

                <div
                  className={
                    networkListOpenStat
                      ? "nav-shrink-arrow-network-list"
                      : "nav-expand-arrow-network-list"
                  }></div>
              </div>
              {networkListOpenStat && <NetworkDropdown />}
            </div>

            <WalletConnectBtn btnFor={"NotHomePage"} />
          </div>
        </nav>

        <div className="content-setter"></div>
      </div>

      <div className="mobile-nav-wrapper">
        <Link to="/create-token" className="nav-logo-header">
          <img src={BlueCoreLogo} alt="" height={40} width={40} />
        </Link>
        <WalletConnectBtn btnFor={"NotHomePage"} />
      </div>
    </>
  );
}
