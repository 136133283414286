import React from "react";
import "../../ultimate-token/sub-components/SubComponents.css";
import { useUltimateTokenContext } from "../../../context/UltimateContext";

export default function DividendTokenSupply({ handleSetSession }) {
  const { state, dispatch } = useUltimateTokenContext();

  const handleChangeInput = (field, value) => {
    dispatch({
      type: "UPDATE_FORM_DATA",
      section: "tokenSupply",
      payload: { [field]: value },
    });
  };

  return (
    <section className="token-create-main-cont">
      <div className="box-bg">
        <div className="token-create-heading-cont">
          <div>Token Supply</div>
          <div>Let's set the supply options for your token.</div>
        </div>

        <div className="token-supply-input-cont-col2">
          <div className="tsicc2-input">
            <div>Initial Supply</div>
            <input
              type="number"
              onChange={(e) =>
                handleChangeInput("initialSupply", e.target.value)
              }
              value={state.tokenSupply.initialSupply}
              onWheel={(e) => e.target.blur()}
              name="dtInitialSupply"
            />
          </div>
        </div>
      </div>

      <div className="nxt-prev-btn-cont">
        <button
          className="grey-btn"
          onClick={() => handleSetSession("DividendTokenInformation")}>
          Previous
        </button>
        <button
          className="gradient-btn"
          onClick={() => handleSetSession("DividendConfigureDividends")}>
          Next
        </button>
      </div>
    </section>
  );
}
