let AllEnvApiUrls = {
  production: {
    baseUrl: process.env.REACT_APP_PROD_API_URL,
  },
  staging: {
    baseUrl: process.env.REACT_APP_STAG_API_URL,
  },
  development: {
    baseUrl: process.env.REACT_APP_DEV_API_URL,
  },
};

const ApiUrls = AllEnvApiUrls[process.env.REACT_APP_APP_ENVIRONMENT];

// here is API url to show data
export const APIURL = {
  // AUTH API's
  sendTokenData: `${ApiUrls.baseUrl}/v1/liquidity/token-info`,
  getTokenData: `${ApiUrls.baseUrl}/v1/liquidity/get-lptoken-info`,
  fetchTokenPrice: `${ApiUrls.baseUrl}/v1/admin/setCoreTokenPrice`,
  fetchDeflationaryTOkenPrice: `${ApiUrls.baseUrl}/v1/admin/setDeflationaryToken`,
  contact: `${ApiUrls.baseUrl}/v1/liquidity/contact-us`,
};
export default APIURL;
