import {
  EthereumClient,
  w3mConnectors,
  w3mProvider,
} from "@web3modal/ethereum";
import { configureChains, createConfig } from "wagmi";
import {
  base,
  baseSepolia,
  bsc,
  bscTestnet,
  mainnet,
  sepolia,
  polygon,
  polygonMumbai,
  linea,
  lineaTestnet,
  arbitrum,
  arbitrumSepolia,
  avalanche,
  avalancheFuji,
  arbitrumNova,
  opBNB,
  opBNBTestnet,
  optimism,
  optimismSepolia,
  optimismGoerli,
} from "wagmi/chains";

const chains = [
  base,
  baseSepolia,
  bsc,
  bscTestnet,
  mainnet,
  sepolia,
  polygon,
  polygonMumbai,
  linea,
  lineaTestnet,
  arbitrum,
  arbitrumSepolia,
  avalanche,
  avalancheFuji,
  arbitrumNova,
  opBNB,
  opBNBTestnet,
  optimism,
  optimismSepolia,
  optimismGoerli,
];

export const projectId = process.env.REACT_APP_WALLET_PROJECT_ID;

let publicClient;

try {
  ({ publicClient } = configureChains(chains, [w3mProvider({ projectId })]));
} catch (error) {
  console.error("Error configuring public client:", error);
}

export const wagmiConfig = createConfig({
  autoConnect: true,
  connectors: (() => {
    try {
      return w3mConnectors({ version: 1, projectId, chains });
    } catch (error) {
      console.error("Error in w3mConnectors:", error);
    }
  })(),
  publicClient,
});

let ethereumClient;

try {
  ethereumClient = new EthereumClient(wagmiConfig, chains);
} catch (error) {
  console.error("Error creating Ethereum client:", error);
}

export { ethereumClient };
