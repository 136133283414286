import React from 'react'

export default function TokenCreatorBadge({ badgeValue, badgeFor }) {
    const style = {
        padding: '2px 10px',
        background: 'linear-gradient(rgb(26 25 48), rgb(27 26 48)) padding-box, linear-gradient(90deg, #3E4ED3 -5%, #66AAFE 50.42%, #7027C8 105.83%) border-box',
        borderRadius: '20px',
        border: '1px solid transparent',
        display: 'inline-block',
        marginRight: '10px',
        marginBottom: '15px',
        fontSize: '15px',
    }

    const style2 = {
        padding: '2px 10px',
        background: 'linear-gradient(rgb(45 44 65), rgb(44 42 64)) padding-box, linear-gradient(90deg, #3E4ED3 -5%, #66AAFE 50.42%, #7027C8 105.83%) border-box',
        borderRadius: '20px',
        border: '1px solid transparent',
        display: 'inline-block',
        marginRight: '10px',
        marginBottom: '15px',
        fontSize: '15px',
    }

    return (
        <div style={badgeFor === 'tokenBox' ? style2 : style}>{badgeValue}</div>
    )
}

