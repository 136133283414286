import React, { useState } from "react";
import { useWeb3Modal } from "@web3modal/react";
import { useAccount, useChainId } from "wagmi";
import { conciseAddress } from "./WalletHelper";
import "./WalletConnectBtn.css";
import CopyIcon from "../../assets/create-token/copy_icon.png";
import BaseIcon from "../../assets/network_iocns/base_network_icon.png";
import SuccessIcon from "../../assets/green_successpng.png";

export default function WalletConnectBtn({ btnFor }) {
  const { open } = useWeb3Modal();
  const { address } = useAccount();
  const chainId = useChainId();
  const [copiedStatus, setCopiedStatus] = useState(false);

  const handleOpenModal = async () => {
    try {
      await open();
    } catch (error) {
      console.error("Error opening web3 modal:", error);
    }
  };

  const handleCopyText = () => {
    navigator.clipboard
      .writeText(address)
      .then(() => {
        setCopiedStatus(true);
      })
      .catch(() => {
        setCopiedStatus(false);
      });
  };

  return (
    <section className="wallet_container">
      {chainId !== 84532 && chainId !== 8453 && address ? (
        <div
          className="wlt-cnt-btn-wrng-net"
          id="connect"
          onClick={handleOpenModal}>
          Unsupported Network
        </div>
      ) : (
        <>
          {btnFor === "homePage" && address && (chainId === 84532 || 8453) ? (
            <div className="wallet-connect-btn">
              <div
                className="wlt-cnt-btn"
                id="connect"
                onClick={handleOpenModal}>
                <img
                  src={BaseIcon}
                  alt=""
                  height={20}
                  width={20}
                  style={{ marginRight: "10px" }}
                />
                {address ? conciseAddress(address) : "Connect Wallet"}
              </div>

              <div className="wlt-cnt-cpy-btn" onClick={handleCopyText}>
                <img
                  src={!copiedStatus ? CopyIcon : SuccessIcon}
                  alt=""
                  height={20}
                  width={20}
                />
              </div>
            </div>
          ) : (
            <div
              className="wlt-cnt-btn-not-cnt"
              id="connect"
              onClick={handleOpenModal}>
              {address ? conciseAddress(address) : "Connect Wallet"}
            </div>
          )}
        </>
      )}
    </section>
  );
}
