import React from "react";
import "../../ultimate-token/sub-components/SubComponents.css";
import Toggle from "../../../utills/Toggle/Toggle";
import MyInput from "../../ultimate-token/sub-components/MyInput";
import { useUltimateTokenContext } from "../../../context/UltimateContext";

export default function DividendLiquidityOptions({ handleSetSession }) {
  const { state, dispatch } = useUltimateTokenContext();

  const handleInputChange = (field, value) => {
    dispatch({
      type: "UPDATE",
      section: "liquidityOption",
      payload: { [field]: value },
    });
  };

  const setToggleValue = (field, data) => {
    handleInputChange(field, data);
  };

  const handleChange = (field, value) => {
    handleInputChange(field, value);
  };

  return (
    <section className="token-create-main-cont">
      <div className="box-bg tc-liq-cont">
        <div className="token-create-heading-cont">
          <div>Liquidity Options</div>
          <div>Quickly add liquidity for your token.</div>
        </div>

        <div className="tc-toggle-cont tc-box-responsive toggle-mk-rs-sm">
          <div>
            <div>Add Initial Liquidity</div>
            <div>
              Automatically create and fund the liquidity pool, this will allow
              users to swap your token.
            </div>
          </div>

          <Toggle
            setToggleValue={setToggleValue}
            value={state.liquidityOption.addLiquidity}
            keyValue={"addLiquidity"}
          />
        </div>

        {state.liquidityOption.addLiquidity && (
          <>
            <div className="tc-liq-section-cont">
              <div className="tc-toggle-cont">
                <div>
                  <div>Token Paring & Funding</div>
                  <div>
                    Pair a percentage of your token supply with ETH to fund the
                    liquidity pool. We recommend pairing at least 10% of your
                    token supply with at least 1 ETH.
                  </div>
                </div>
              </div>

              <div className="tc-liq-token-supply">
                <div>
                  <div>Token Supply (%):</div>
                  <div className="tc-liq-total-supply-bar-cont">
                    <MyInput
                      min={0}
                      max={100}
                      value={state.liquidityOption.tokenAmount}
                      param={"tokenAmount"}
                      handleChange={handleChange}
                    />
                    <div>{state.liquidityOption.tokenAmount}%</div>
                  </div>
                </div>

                <div className="tsicc2-input">
                  <div>ETH Amount:</div>
                  <input
                    type="number"
                    name="ethAmount"
                    placeholder="1"
                    value={state.liquidityOption.ethAmount}
                    onChange={(e) =>
                      handleInputChange("ethAmount", e.target.value)
                    }
                    onWheel={(e) => e.target.blur()}
                  />
                </div>
              </div>

              <div className="tc-liq-tok-pr-cont">
                <div className="tc-liq-tok-price">
                  <div>
                    <div>Token</div>
                    <div>
                      {(state.tokenSupply.initialSupply *
                        state.liquidityOption.tokenAmount) /
                        100}
                    </div>
                  </div>
                  <div>
                    <div>ETH</div>
                    <div>
                      {state.liquidityOption.ethAmount
                        ? state.liquidityOption.ethAmount
                        : 1}
                    </div>
                  </div>
                </div>

                <div className="tc-liq-circle-design">
                  <div>+</div>
                </div>
              </div>

              <div className="tc-liq-tok-text">
                Based on the selected options and values above, your token will
                launch with the following initial parameters, including the
                starting market cap and the starting price of your token.
              </div>

              <div className="tc-liq-tok-price tc-liq-tk-pr-sc-cont">
                <div>
                  <div>Launch Market Cap</div>
                  <div>
                    {(
                      (state.tokenSupply.initialSupply /
                        ((state.tokenSupply.initialSupply *
                          state.liquidityOption.tokenAmount) /
                          100)) *
                      state.liquidityOption.ethAmount
                    ).toFixed(2)}{" "}
                    ETH (~13,856.56 USD)
                  </div>
                </div>
                <div>
                  <div>Launch Token Price</div>
                  <div>
                    {(
                      (state.tokenSupply.initialSupply /
                        (state.tokenSupply.initialSupply *
                          state.liquidityOption.tokenAmount) /
                        100) *
                      state.liquidityOption.ethAmount
                    ).toFixed(6)}{" "}
                    (~1.39 USD)
                  </div>
                </div>
              </div>
            </div>

            <div className="ticc2-select-cont">
              <div>Exchange (DEX):</div>
              <div>
                We'll use the selected DEX to create your liquidity pair and
                seed the initial pool.
              </div>
              <select
                value={state.liquidityOption.dexExchange || ""}
                onChange={(e) =>
                  handleInputChange("dexExchange", e.target.value)
                }>
                <option disabled value="">
                  Please Select
                </option>
                <option value="0x1926f2BEc4cd34613ccAaC82911204B87cf590a2">
                  Bluedex (Main-net)
                </option>
                <option value="0xAEDfd05a6d0129670751b379C1623a0FD0bFBa16">
                  Bluedex (Test-net)
                </option>
              </select>
            </div>

            <div className="tc-liq-action-cont ticc2-select-cont">
              <div style={{ position: "relative" }}>
                Liquidity Action & Ownership:{" "}
                <div className="liquidy-coming-soon">
                  <div className="tc-triangle-design-liq"></div>{" "}
                  <div className="tc-badge-liq">Coming Soon</div>
                </div>
              </div>

              <div>
                By default, the liquidity will be sent to you. However, you can
                choose to burn or lock the liquidity.
              </div>

              <div>
                <div
                  className={` ${
                    state.liquidityOption.liquidityOwnership === "None"
                      ? "tc-liq-non-brn-lck-active"
                      : "tc-liq-non-brn-lck"
                  }`}
                  onClick={() =>
                    handleInputChange("liquidityOwnership", "None")
                  }>
                  None
                </div>
                <div
                  className={` ${
                    state.liquidityOption.liquidityOwnership === "Burn"
                      ? "tc-liq-non-brn-lck-active"
                      : "tc-liq-non-brn-lck"
                  }`}
                  onClick={() =>
                    handleInputChange("liquidityOwnership", "Burn")
                  }>
                  Burn
                </div>
                <div
                  className={` ${
                    state.liquidityOption.liquidityOwnership === "Lock"
                      ? "tc-liq-non-brn-lck-active"
                      : "tc-liq-non-brn-lck"
                  }`}
                  onClick={() =>
                    handleInputChange("liquidityOwnership", "Lock")
                  }>
                  Lock
                </div>
              </div>

              <div>Liquidity will be sent to your wallet.</div>
            </div>
          </>
        )}
      </div>

      <div className="nxt-prev-btn-cont">
        <button
          className="grey-btn"
          onClick={() => handleSetSession("DividendConfigureFees")}>
          Previous
        </button>
        <button
          className="gradient-btn"
          onClick={() => handleSetSession("DividenDeployToken")}>
          Next
        </button>
      </div>
    </section>
  );
}
