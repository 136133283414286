import CryptoJS from "crypto-js";

export default function SecureKyeGen(data, secretkey, timeInSecond) {
  const today = new Date();

  const now_utc = Date.UTC(
    today.getUTCFullYear(),
    today.getUTCMonth(),
    today.getUTCDate(),
    today.getUTCHours(),
    today.getUTCMinutes(),
    today.getUTCSeconds() + timeInSecond
  );

  const utcTime = new Date(now_utc);

  const salt16 = CryptoJS.lib.WordArray.random(16); // Random 16 bytes salt

  // Derive key and IV via PBKDF2
  const keyIV = CryptoJS.PBKDF2(secretkey, salt16, {
    keySize: (32 + 16) / 4, // 12 words a 4 bytes = 48 bytes
    iterations: 1000, // Choose a sufficiently high iteration count
    hasher: CryptoJS.algo.SHA256, // Default digest is SHA-1
  });
  const key32 = CryptoJS.lib.WordArray.create(keyIV.words.slice(0, 32 / 4)); // 8 words a 4 bytes = 32 bytes
  const iv16 = CryptoJS.lib.WordArray.create(
    keyIV.words.slice(32 / 4, (32 + 16) / 4)
  ); // 4 words a 4 bytes = 16 bytes

  // Encrypt
  const message = String(data) + `-->${now_utc}`;
  const cipherParams = CryptoJS.AES.encrypt(message, key32, { iv: iv16 });
  const ciphertext = cipherParams.ciphertext;

  // Concatenate salt and ciphertext
  const saltCiphertext = salt16.clone().concat(ciphertext);
  const saltCiphertextB64 = saltCiphertext.toString(CryptoJS.enc.Base64);
  return saltCiphertextB64;
}
