import React from "react";
import "./Toggle.css";

export default function Toggle({ keyValue, value, setToggleValue }) {
  // Function to handle checkbox change
  const handleCheckboxChange = () => {
    setToggleValue(keyValue, !value);
  };

  return (
    <label className="switch">
      <input type="checkbox" checked={value} onChange={handleCheckboxChange} />
      <span className="slider round"></span>
    </label>
  );
}
