import React, { useEffect, useState } from "react";
import "./ReviewToken.css";
import UTFactories from "../../../utills/abi/UTFactories.json";
import ReviewTokenIcon from "../../../assets/create-token/review-token-icon.png";
import { parseEther } from "ethers";
import { useUltimateTokenContext } from "../../../context/UltimateContext";
import {
  useAccount,
  useContractWrite,
  useWaitForTransaction,
  useContractEvent,
  useBalance,
  useChainId,
} from "wagmi";
import axios from "axios";
import Loader from "../../../utills/Modal/Loader";
import ContractDeployed from "../../../utills/Modal/ContractDeployed";
import LiquidityAddedModal from "../../../utills/Modal/LiquidityAddedModal";
import { conciseAddress } from "../../../utills/wallet/WalletHelper";
import LeftArrow from "../../../assets/create-token/left-arrow.png";
import { Tooltip } from "react-tooltip";
import WalletConnectBtn from "../../../utills/wallet/WalletConnectBtn";
import APIURL from "../../../utills/APIs/APIURL";
import SecureKyeGen from "../../../utills/secureKeyGen/SecureKeyGen";

export default function UtReviewToken({ handleSetSession }) {
  const { state } = useUltimateTokenContext();
  const { address } = useAccount();
  const chainId = useChainId();
  const [show, setShow] = useState(false);
  const [apiRes, setApiRes] = useState(false);
  const [liquidityDetails, setLiquidityDetails] = useState({});
  const handeShowPopup = (data) => {
    setShow(true);
  };
  const [usdEthPrice, setUsdEthPrice] = useState(1);
  const { data: walletBalance } = useBalance({ address: address });
  const [factoryAddress, setFactoryAddress] = useState({
    coreToken: "",
    coreTokenBurn: "",
  });

  useEffect(() => {
    if (chainId === 8453) {
      setFactoryAddress((prevState) => ({
        ...prevState,
        coreToken: process.env.REACT_APP_BASE_CORE_FACTORY,
        coreTokenBurn: process.env.REACT_APP_BASE_CORE_BURN_FACTORY,
      }));
    } else if (chainId === 84532) {
      setFactoryAddress((prevState) => ({
        ...prevState,
        coreToken: process.env.REACT_APP_BASE_SEPOLIA_CORE_FACTORY,
        coreTokenBurn: process.env.REACT_APP_BASE_SEPOLIA_CORE_BURN_FACTORY,
      }));
    } else {
      setFactoryAddress((prevState) => ({
        ...prevState,
        coreToken: "",
        coreTokenBurn: "",
      }));
    }
  }, [chainId]);

  const postableData = {
    _owner: address,
    _mintTarget: address,
    _name: state.tokenInfo.tokenName,
    _symbol: state.tokenInfo.tokenSymbol,
    _decimals: state.tokenInfo.changeDecimal,
    _initialSupply: state.tokenSupply.initialSupply,
    _requiredLiquidity: state.liquidityOption.addLiquidity,
    _liquidityAmount: `${
      (state.tokenSupply.initialSupply * state.liquidityOption.tokenAmount) /
      100
    }`,
    _ethAmountForLiquidity:
      Number(state.liquidityOption.ethAmount) +
      Number("0.1") +
      Number("0.0021347"),
  };

  const {
    write: ultimateTokenWrite,
    data: ultimateTokenData,
    isLoading: ultimateTokenLoading,
  } = useContractWrite({
    address: `${
      state.additional.burnReport
        ? factoryAddress?.coreTokenBurn
        : factoryAddress?.coreToken
    }`,
    abi: UTFactories,
    functionName: "createCoreToken",
    args: [
      postableData._owner,
      postableData._name,
      postableData._symbol,
      postableData._decimals,
      postableData._initialSupply,
      postableData._requiredLiquidity,
      postableData._liquidityAmount,
    ],
    value: [
      parseEther(
        state.liquidityOption.ethAmount
          ? `${(
              Number(state.liquidityOption.ethAmount) +
              (state.additional.burnReport
                ? Number(
                    (
                      Number(state.fees.baseFee) + Number(state.fees.burnFee)
                    ).toFixed("3")
                  )
                : Number(state.fees.baseFee))
            ).toFixed(6)}`
          : state.additional.burnReport
          ? `${(
              Number(state.fees.baseFee) + Number(state.fees.burnFee)
            ).toFixed("3")}`
          : `${state.fees.baseFee}`
      ),
    ],
    onError(error) {
      console.error(error);
    },
  });

  const handleTokenData = async (contractAddress) => {
    const body = {
      network_name: sessionStorage.getItem("chain"),
      tokenName: state.tokenInfo.tokenName,
      totalSupply: `${state.tokenSupply.initialSupply}`,
      liquidityAmount: `${
        (state.tokenSupply.initialSupply * state.liquidityOption.tokenAmount) /
        100
      }`,
      userWalletAddres: address,
      contractAddress: contractAddress,
      decimal: state.tokenInfo.changeDecimal,
      ethAmountForLiquidity: state.liquidityOption.ethAmount,
    };

    try {
      await axios
        .post(`${APIURL.sendTokenData}`, body, {
          headers: {
            Authorization: `Bearer ${SecureKyeGen(
              address,
              process.env.REACT_APP_API_SECRET_KEY,
              Number(process.env.REACT_APP_API_SECRET_TIME)
            )}`,
          },
        })
        .then((res) => {})
        .catch((err) => {
          console.error(err);
        });
    } catch (error) {
      console.error(error);
    }
  };

  const getTokenData = (contractAddress) => {
    axios
      .get(`${APIURL.getTokenData}/${contractAddress}`, {
        headers: {
          Authorization: `Bearer ${SecureKyeGen(
            address,
            process.env.REACT_APP_API_SECRET_KEY,
            Number(process.env.REACT_APP_API_SECRET_TIME)
          )}`,
        },
      })
      .then((res) => {
        if (res?.data?.status === 200) {
          setApiRes(true);
          setLiquidityDetails(res?.data?.data);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const { data: ultimateTokenWaitData, isLoading: ultimateTokenWaitLoading } =
    useWaitForTransaction({
      hash: ultimateTokenData?.hash,
      onSuccess(data, error) {
        if (data) {
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });

          handeShowPopup(data);

          if (state.liquidityOption.addLiquidity === true) {
            handleTokenData(data?.logs[0].address);

            setTimeout(() => {
              getTokenData(data?.logs[0].address);
            }, 60000);
          }
        }
      },
    });

  useContractEvent({
    address: `${
      state.additional.burnReport
        ? factoryAddress?.coreTokenBurn
        : factoryAddress?.coreToken
    }`,
    abi: UTFactories,
    eventName: "CoreTokenCreated",
    listener(
      parameter1,
      parameter2,
      parameter3,
      parameter4,
      parameter5,
      parameter6
    ) {
      // console.log(
      //   parameter1,
      //   parameter2,
      //   parameter3,
      //   parameter4,
      //   parameter5,
      //   parameter6
      // );
    },
  });

  const handleCreateToken = () => {
    try {
      ultimateTokenWrite();
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=usd"
        );
        setUsdEthPrice(response?.data?.ethereum?.usd);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      {ultimateTokenLoading ||
        (ultimateTokenWaitLoading && (
          <div className="popup_bg">
            <Loader contentProps={"Deploying Contract"} />
          </div>
        ))}

      {show && (
        <div className="popup_bg">
          <ContractDeployed
            depContAddress={ultimateTokenWaitData?.logs[0].address}
          />

          {apiRes === true ? (
            <LiquidityAddedModal depContDetails={liquidityDetails} />
          ) : state.liquidityOption.addLiquidity ? (
            <Loader contentProps={"Adding Liquidity"} />
          ) : (
            ""
          )}
        </div>
      )}

      <div className="review-token-cont">
        <div className="review-token-head-cont">
          <div className="review-token-icon-cont">
            <img src={ReviewTokenIcon} alt="" />
          </div>
          <div>Review Your Token</div>
        </div>

        {/* <div className="review-token-box review-token-box-phone"> */}
        {/* <div className="rtt-top-sec">
            <div className="rtt-top-head-sec rth-top-res">
              <div
                className="rtt-arrow-left"
                onClick={() => handleSetSession("UtDeployToken")}></div>
              <div className="">Token Information</div>
            </div>
            <div className="rt-box-data">
              <div>Token Name</div>
              <div>{state.tokenInfo.tokenName}</div>
            </div>
            <div className="rt-box-data">
              <div>Token Symbol</div>
              <div>{state.tokenInfo.tokenSymbol}</div>
            </div>
            <div className="rt-box-data">
              <div>Blockchain Network</div>
              <div>{state.tokenInfo.chainNetwork || "Base Sepolia"}</div>
            </div>
          </div> */}

        <div className="review-token-box">
          <div className="rt-box-head input-heading-cont">
            Token Information{" "}
            <span id="utrtTokenInformation" className="tooltip-design">
              i
            </span>
            <Tooltip
              anchorSelect="#utrtTokenInformation"
              className="styletooltip"
              style={{
                backgroundColor: "#12141a",
                borderRadius: "12px",
                zIndex: "11111",
                width: "400px",
                textAlign: "center",
                fontSize: "15px",
                fontWeight: "400",
              }}>
              This section provides an overview of the key details of your
              token, including its name, symbol, blockchain network, supply,
              liquidity options, and ownership. Review these details carefully
              before proceeding with deployment to ensure accuracy and alignment
              with your project goals.
            </Tooltip>
          </div>
          <div className="rt-box-data">
            <div className="input-heading-cont">
              Token Name{" "}
              <span id="utrtTokenName" className="tooltip-design">
                i
              </span>
              <Tooltip
                anchorSelect="#utrtTokenName"
                className="styletooltip"
                style={{
                  backgroundColor: "#12141a",
                  borderRadius: "12px",
                  zIndex: "11111",
                  width: "400px",
                  textAlign: "center",
                  fontSize: "15px",
                  fontWeight: "400",
                }}>
                This is the name of your token as it will appear on the
                blockchain and in wallets. Choose a name that represents your
                project or brand effectively.
              </Tooltip>
            </div>
            <div>{state.tokenInfo.tokenName}</div>
          </div>
          <div className="rt-box-data">
            <div className="input-heading-cont">
              Token Symbol{" "}
              <span id="utrtTokenSymbol" className="tooltip-design">
                i
              </span>
              <Tooltip
                anchorSelect="#utrtTokenSymbol"
                className="styletooltip"
                style={{
                  backgroundColor: "#12141a",
                  borderRadius: "12px",
                  zIndex: "11111",
                  width: "400px",
                  textAlign: "center",
                  fontSize: "15px",
                  fontWeight: "400",
                }}>
                The token symbol is a unique abbreviation for your token, used
                to represent it in wallets, exchanges, and other platforms.
              </Tooltip>
            </div>
            <div>{state.tokenInfo.tokenSymbol}</div>
          </div>
          <div className="rt-box-data">
            <div className="input-heading-cont">
              Blockchain Network{" "}
              <span id="utrtBlockchainNetwork" className="tooltip-design">
                i
              </span>
              <Tooltip
                anchorSelect="#utrtBlockchainNetwork"
                className="styletooltip"
                style={{
                  backgroundColor: "#12141a",
                  borderRadius: "12px",
                  zIndex: "11111",
                  width: "400px",
                  textAlign: "center",
                  fontSize: "15px",
                  fontWeight: "400",
                }}>
                The blockchain network determines where your token will be
                deployed. Ensure compatibility with your chosen network's
                standards and requirements.
              </Tooltip>
            </div>
            <div>
              {state.tokenInfo.chainNetwork ||
                `${
                  chainId === 84532
                    ? "Base Sepolia"
                    : chainId === 8453 && "Base"
                }`}
            </div>
          </div>
        </div>

        <div className="review-token-box">
          <div className="rt-box-head input-heading-cont">
            Token Supply{" "}
            <span id="utrtTokenSupply" className="tooltip-design">
              i
            </span>
            <Tooltip
              anchorSelect="#utrtTokenSupply"
              className="styletooltip"
              style={{
                backgroundColor: "#12141a",
                borderRadius: "12px",
                zIndex: "11111",
                width: "400px",
                textAlign: "center",
                fontSize: "15px",
                fontWeight: "400",
              }}>
              Token supply refers to the total number of tokens that will be
              available for circulation. Initial supply is the number of tokens
              created at launch, while maximum supply sets an upper limit on the
              total token supply.
            </Tooltip>
          </div>
          <div className="rt-box-data">
            <div>Initial Supply</div>
            <div>{state.tokenSupply.initialSupply}</div>
          </div>
          {/* <div className="rt-box-data">
            <div>Maximum Supply</div>
            <div>{state.tokenSupply.initialSupply}</div>
          </div> */}
        </div>

        <div className="review-token-box">
          <div className="rt-box-head input-heading-cont">
            Liquidity Options
            <span id="utrtLiqOptions" className="tooltip-design">
              i
            </span>
            <Tooltip
              anchorSelect="#utrtLiqOptions"
              className="styletooltip"
              style={{
                backgroundColor: "#12141a",
                borderRadius: "12px",
                zIndex: "11111",
                width: "400px",
                textAlign: "center",
                fontSize: "15px",
                fontWeight: "400",
              }}>
              Here, you can review the token supply allocated for the launch and
              the corresponding ETH amount you've chosen to pair with your
              token. This decision impacts the initial liquidity pool and
              ultimately affects the trading dynamics of your token. Ensure your
              choices align with your project objectives and liquidity
              requirements before proceeding with deployment.
            </Tooltip>
          </div>
          <div className="rt-box-data">
            <div>Token Supply</div>
            <div>
              {state.liquidityOption.addLiquidity
                ? `${state.liquidityOption.tokenAmount} %`
                : "N/A"}
            </div>
          </div>
          <div className="rt-box-data">
            <div>ETH Amount</div>
            <div>
              {state.liquidityOption.addLiquidity
                ? state.liquidityOption.ethAmount
                : 0}
            </div>
          </div>
          <div className="rt-box-data">
            <div className="input-heading-cont">
              Launch Market Cap{" "}
              <span id="utrtLaunchMarkCap" className="tooltip-design">
                i
              </span>
              <Tooltip
                anchorSelect="#utrtLaunchMarkCap"
                className="styletooltip"
                style={{
                  backgroundColor: "#12141a",
                  borderRadius: "12px",
                  zIndex: "11111",
                  width: "400px",
                  textAlign: "center",
                  fontSize: "15px",
                  fontWeight: "400",
                }}>
                The launch market cap represents the estimated value of your
                token at launch, based on the initial token price and supply. It
                provides insight into the potential market value of your
                project.
              </Tooltip>
            </div>
            {state.liquidityOption.addLiquidity ? (
              <div>
                {(
                  (state.tokenSupply.initialSupply /
                    ((state.tokenSupply.initialSupply *
                      state.liquidityOption.tokenAmount) /
                      100)) *
                  state.liquidityOption.ethAmount
                ).toFixed(2)}{" "}
                ETH (~
                {(
                  (
                    (state.tokenSupply.initialSupply /
                      ((state.tokenSupply.initialSupply *
                        state.liquidityOption.tokenAmount) /
                        100)) *
                    state.liquidityOption.ethAmount
                  ).toFixed(2) * usdEthPrice
                ).toFixed("2")}{" "}
                USD)
              </div>
            ) : (
              <div> 0 ETH (~0 USD) </div>
            )}
          </div>
          <div className="rt-box-data">
            <div className="input-heading-cont">
              Launch Token Price{" "}
              <span id="utrtLaunchTokenPrice" className="tooltip-design">
                i
              </span>
              <Tooltip
                anchorSelect="#utrtLaunchTokenPrice"
                className="styletooltip"
                style={{
                  backgroundColor: "#12141a",
                  borderRadius: "12px",
                  zIndex: "11111",
                  width: "400px",
                  textAlign: "center",
                  fontSize: "15px",
                  fontWeight: "400",
                }}>
                The launch token price is the initial price at which your token
                will be available for trading on decentralised exchanges (DEXs).
                It plays a crucial role in determining the token's value and
                market perception.
              </Tooltip>
            </div>
            {state.liquidityOption.addLiquidity ? (
              <div>
                {(
                  (state.tokenSupply.initialSupply /
                    (state.tokenSupply.initialSupply *
                      state.liquidityOption.tokenAmount) /
                    100) *
                  state.liquidityOption.ethAmount
                ).toFixed(6)}{" "}
                (~
                {(
                  (
                    (state.tokenSupply.initialSupply /
                      (state.tokenSupply.initialSupply *
                        state.liquidityOption.tokenAmount) /
                      100) *
                    state.liquidityOption.ethAmount
                  ).toFixed(6) * usdEthPrice
                ).toFixed("2")}{" "}
                USD)
              </div>
            ) : (
              <div> 0 ETH (~0 USD) </div>
            )}
          </div>
          <div className="rt-box-data">
            <div className="input-heading-cont">
              Token Ownership{" "}
              <span id="utrtTokenOwnership" className="tooltip-design">
                i
              </span>
              <Tooltip
                anchorSelect="#utrtTokenOwnership"
                className="styletooltip"
                style={{
                  backgroundColor: "#12141a",
                  borderRadius: "12px",
                  zIndex: "11111",
                  width: "400px",
                  textAlign: "center",
                  fontSize: "15px",
                  fontWeight: "400",
                }}>
                The token owner address is the Ethereum address that will have
                ownership and control over the deployed token contract. In this
                case, the wallet you’re currently connected with will be the
                deployer wallet and therefore, the owner. Ensure the security
                and integrity of this address as it holds significant authority
                over the token. Once deployed, you can change the contract owner
                at anypoint through the relevant scan website.
              </Tooltip>
            </div>
            <div>{conciseAddress(address)}</div>
          </div>
        </div>

        <div className="rt-btn-cont rt-btn-section">
          <button
            className="grey-btn"
            onClick={() => handleSetSession("UtDeployToken")}>
            <img src={LeftArrow} alt="" />
          </button>

          {!address ? (
            <WalletConnectBtn />
          ) : (
            <button className="gradient-btn" onClick={handleCreateToken}>
              {ultimateTokenLoading || ultimateTokenWaitLoading
                ? "Loading"
                : `Launch ${state.tokenInfo.tokenName} To ${
                    chainId === 84532
                      ? "Base Sepolia"
                      : chainId === 8453 && "Base"
                  }`}
            </button>
          )}
        </div>
        {/* <div className="rt-balance-cont">
          <div></div>

          {Number(walletBalance?.formatted) <=
            (postableData._requiredLiquidity
              ? postableData._ethAmountForLiquidity.toFixed(8)
              : 0.1) && (
            <div className="input_error">
              Your wallet doesn't have sufficient balance
            </div>
          )}
        </div> */}

        {/* <div className="rtt-section">
          <div className="rt-box-head">Token Supply</div>
          <div className="rt-box-data">
            <div>Initial Supply</div>
            <div>{state.tokenSupply.initialSupply}</div>
          </div>
          <div className="rt-box-data">
            <div>Maximum Supply</div>
            <div>{state.tokenSupply.initialSupply}</div>
          </div>
        </div> */}

        {/* <div className="rtt-section">
          <div className="rt-box-head">Liquidity Options</div>
          <div className="rt-box-data">
            <div>Token Supply</div>
            <div>{state.liquidityOption.tokenAmount}%</div>
          </div>
          <div className="rt-box-data">
            <div>ETH Amount</div>
            <div>
              {state.liquidityOption.ethAmount
                ? state.liquidityOption.ethAmount
                : 0}
            </div>
          </div>
          <div className="rt-box-data">
            <div>Launch Market Cap</div>
            <div>
              {state.liquidityOption.launchMarketCap} ETH (~13,856.56 USD)
            </div>
          </div>
          <div className="rt-box-data">
            <div>Launch Token Price</div>
            <div>{state.liquidityOption.launchTokenPrice} ETH (~1.39 USD)</div>
          </div>
          <div className="rt-box-data">
            <div>Token Ownership</div>
            <div>{conciseAddress(address)}</div>
          </div>
        </div> */}

        {/* <div className="rt-btn-cont">
          <button
            className="gradient-btn phone-size-button"
            onClick={handleCreateToken}>
            {ultimateTokenLoading || ultimateTokenWaitLoading
              ? "Loading"
              : `Launch ${state.tokenInfo.tokenName} To Base Sepolia`}
          </button>
        </div> */}
        {/* </div> */}
      </div>
    </>
  );
}
