import React from 'react';
import { ColorRing } from 'react-loader-spinner';

export default function Loader({ contentProps }) {
    return (
        <div style={{ color: '#ffffff', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
            <div style={{ margin: '15px 0px' }}>
                <ColorRing
                    visible={true}
                    height="50"
                    width="50"
                    ariaLabel="color-ring-loading"
                    wrapperStyle={{}}
                    wrapperClass="color-ring-wrapper"
                    colors={['#ffffff', '#ffffff', '#ffffff', '#ffffff', '#ffffff']}
                />
            </div>
            <p style={{ fontSize: '18px' }}>{contentProps}</p>
        </div>
    )
}