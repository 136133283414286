import React from "react";
import "./TokenFeatures.css";
import SpecIcon from "../../../assets/create-token/spec-ompliant.png";
import AuditedIcon from "../../../assets/create-token/audited.png";
import FomrallyIcon from "../../../assets/create-token/formally-verified.png";
import ScVerifiedIcon from "../../../assets/create-token/source-verified.png";
import AdacIcon from "../../../assets/create-token/advance-ac-control.png";
import TrustConfIcon from "../../../assets/create-token/trust-confidence.png";

export default function TokenFeatures() {
  const TokenFeatureData = {
    aacVerified: {
      icon: AdacIcon,
      heading: "Advanced Access Control",
      subheading: `Complete control`,
      content:
        "Our tokens come with advanced access control, ensuring access stays secure.",
    },
    scVerified: {
      icon: ScVerifiedIcon,
      heading: "Source Code Verified",
      subheading: `Verified before you're live.`,
      content:
        "All of our tokens are pre-verified on all major block explorers, they show up as verified immediately.",
    },
    fomrallyVerified: {
      icon: FomrallyIcon,
      heading: "Formally Verified",
      subheading: "The highest security standard.",
      content:
        "Security audits on all tokens ensure adherence to top security protocols.",
    },
    specComplimant: {
      icon: SpecIcon,
      heading: "Compliant",
      subheading: "Compatible Across All Wallets.",
      content:
        "All of our tokens go through rigorous testing to make sure they are fully compliant with the standards.",
    },
    audited: {
      icon: AuditedIcon,
      heading: "Audited",
      subheading: "Tested by the best.",
      content:
        "Each token undergoes thorough auditing and testing to meet industry standards.",
    },
    tncVerified: {
      icon: TrustConfIcon,
      heading: "Trust & Confidence",
      subheading: "Develop with confidence",
      content: `We pride ourselves in our quality, allowing you, the user, more time to spend where it matters.`,
    },
  };

  return (
    // <div className="kfc-mcd-main">
    <section className="token-features-main">
      {Object.entries(TokenFeatureData).map(([key, value]) => (
        <div className="token-features-container" key={key}>
          <div>
            <img src={value.icon} alt="" />
          </div>

          <div>{value.heading}</div>

          <div>{value.subheading}</div>

          <div>{value.content}</div>
        </div>
      ))}
    </section>
    // </div>
  );
}
