import React from "react";
import "./FAQ.css";
import FAQContent from "./FAQContent";
import Footer from "../create-token/footer/Footer";
import BlueDexLinks from "../create-token/blue-dex-link/BlueDexLinks";

const dataFaqs = [
  {
    heading: "How does Blueprint facilitate token creation?",
    content:
      "Blueprint provides a user-friendly interface where users can easily create custom tokens by specifying details such as token name, symbol, and decimals. The platform generates smart contracts based on these parameters, enabling seamless token creation.",
  },
  {
    heading:
      "Can I customise token details such as name, symbol, and decimals?",
    content:
      "Yes, Blueprint allows users to fully customise token details according to their preferences. Whether it's choosing a unique name, symbol, or defining the number of decimals, users have complete control over the token creation process.",
  },
  {
    heading: "What features does Blueprint offer for token functionality?",
    content:
      "Blueprint offers a comprehensive set of features for token functionality, including minting, burning, and pausing of token transfers. Users can implement these functions to manage token supply and control token transfers as per their requirements.",
  },
  {
    heading: "What blockchain networks does Blueprint currently support?",
    content:
      "Blueprint currently supports Base Sepolia Testnet and Base Mainnet. We will soon be expanding our support to include Ethereum (ETH) and Binance Smart Chain (BNB), providing users with access to a diverse range of blockchain networks.",
  },
  {
    heading: "How does Blueprint help manage token supply?",
    content:
      "Blueprint provides tools for managing token supply, allowing users to set a cap on token creation or opt for an unlimited supply. This feature ensures that the total number of tokens does not exceed the predefined limit, providing assurance to token holders.",
  },
  {
    heading: "Does Blueprint support customisable token economics?",
    content:
      "Yes, Blueprint supports customisable token economics, enabling users to configure taxes for token transactions and create dividend tokens. These features empower token creators to design tokenomics that align with their project goals and user incentives.",
  },
  {
    heading: "What security features does Blueprint provide?",
    content:
      "Blueprint offers robust security measures, including the ability to blacklist addresses and implement maximum wallet and transaction limits. These features enhance token security and protect against unauthorised transactions and malicious activities.",
  },
  {
    heading: "Which wallets support tokens created with Blueprint?",
    content:
      "Tokens created with Blueprint are supported by all major wallets, including hardware wallets like Ledger and Tresor. Users can seamlessly integrate their tokens into their preferred wallet for storage and management.",
  },
  {
    heading: "Can tokens be used on exchanges, DEXs, and DeFi protocols?",
    content:
      "Yes, tokens created with Blueprint can be used on exchanges, decentralised exchanges (DEXs), and decentralised finance (DeFi) protocols. Users can trade, swap, and utilise their tokens across various platforms within the crypto ecosystem.",
  },
  {
    heading: "How does Blueprint ensure the authenticity of token source code?",
    content:
      "Blueprint automatically verifies the source code of tokens created on the platform, ensuring transparency and authenticity. Tokens receive a green checkmark on block explorers like Etherscan, providing users with confidence in the integrity of their tokens.",
  },
  {
    heading:
      "Can I automatically deploy my token to BLUEDEX and provide liquidity?",
    content:
      "Yes, Blueprint allows users to seamlessly deploy their tokens to BLUEDEX, our decentralised exchange platform. Users can also provide liquidity for their tokens on BLUEDEX to facilitate trading pairs and enhance liquidity within the ecosystem.",
  },
  {
    heading: "What is the difference between a token and a coin?",
    content:
      "In simple terms, a token represents a digital asset or utility on a blockchain network, while a coin typically refers to a native currency of a blockchain platform, such as Bitcoin or Ethereum.",
  },
  {
    heading:
      "Can you explain the concept of gas fees in blockchain transactions?",
    content:
      "Gas fees are transaction fees paid to miners for processing and validating transactions on a blockchain network. They ensure the security and efficiency of the network by incentivising miners to include transactions in blocks.",
  },
  {
    heading: "What are the differences between testnet and mainnet deployment?",
    content:
      "Testnet is a testing environment where users can deploy and experiment with their tokens or applications without using real funds. Mainnet, on the other hand, is the live blockchain network where actual transactions and operations take place with real cryptocurrencies.",
  },
  {
    heading:
      "Can I deploy my token to both testnet and mainnet for testing and production purposes?",
    content:
      "Yes, Blueprint allows users to deploy their tokens to both testnet and mainnet. This enables developers to test their tokens in a controlled environment before deploying them to the live network for real-world use.",
  },
  {
    heading:
      "Suppose I want to create a utility token for a gaming platform. How can Blueprint help me achieve this, and what steps do I need to take?",
    content:
      "With Blueprint, you can easily create a custom utility token for your gaming platform by specifying the token details such as name, symbol, and decimals. Once the token is created, you can implement additional features such as minting, burning, and setting token economics to incentivise users within your gaming ecosystem.",
  },
];

export default function FAQ() {
  return (
    <section className="faq-container">
      <div className="faq-top-section">
        <div>Frequently Asked Questions</div>
        <div>You have questions, we have answers. Feel free to ask more!</div>
        <div>
          <a href="https://discord.com/invite/Xk3BGz9zuC" target="_blank">
            Discord
          </a>
          <a href="https://t.me/OfficialBluecoreLabs" target="_blank">
            Telegram
          </a>
        </div>
      </div>

      <FAQContent faqContent={dataFaqs} />

      <BlueDexLinks />

      <Footer />
    </section>
  );
}
