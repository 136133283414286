import React, { useState } from "react";
import "../../ultimate-token/sub-components/ReviewToken.css";
import DTFactories from "../../../utills/abi/DTFactories.json";
import ReviewTokenIcon from "../../../assets/create-token/review-token-icon.png";
import { parseEther } from "ethers";
import { useUltimateTokenContext } from "../../../context/UltimateContext";
import {
  useAccount,
  useContractWrite,
  useWaitForTransaction,
  useContractEvent,
} from "wagmi";
import { conciseAddress } from "../../../utills/wallet/WalletHelper";
import LeftArrow from "../../../assets/create-token/left-arrow.png";

export default function DividendReviewToken({ handleSetSession }) {
  const { state } = useUltimateTokenContext();
  const { address } = useAccount();

  // popup of success
  const [show, setShow] = useState(false);
  const [msg, setMsg] = useState("");

  const handeShowPopup = (data) => {
    setMsg(data);
    setShow(true);
  };

  const handleClosePopup = () => {
    setShow(false);
  };

  const postableData = {
    owner: address,
    name: state?.tokenInfo.tokenName,
    symbol: state?.tokenInfo.tokenSymbol,
    decimals: state?.tokenInfo.changeDecimal,
    initialSupply: state?.tokenSupply.initialSupply,
    routerV2: "0x4C61D6dFbae61179cdDA923f3C1136b3BfC47Cc3",
    baseTaxes: {
      liquidityFeeOnBuy: state?.configureFees.liqFeeBuy,
      liquidityFeeOnSell: state?.configureFees.liqFeeSell,
      operationsFeeOnBuy: state?.configureFees.markOpFeeBuy,
      operationsFeeOnSell: state?.configureFees.markOpFeeSell,
      burnFeeOnBuy: state?.configureFees.burnFeeBuy,
      burnFeeOnSell: state?.configureFees.burnFeeSell,
      applyTaxOnTransfer: false,
    },
    liquidityWallet: address,
    operationsWallet: state?.configureFees.markOpWallet,
    burnWallet: address,
    maxWalletPct: "0",
    maxTxPct: "0",
  };

  const {
    write: deployTokenWrite,
    data: deflationaryTokenData,
    isLoading: deflationaryTokenLoading,
  } = useContractWrite({
    address: "0x29312F062a7A91D1e3c0E04A9D2967B74013de36",
    abi: DTFactories,
    functionName: "deployToken",
    args: [
      postableData.owner,
      postableData.name,
      postableData.symbol,
      postableData.decimals,
      postableData.initialSupply,
      postableData.routerV2,
      postableData.baseTaxes,
      postableData.liquidityWallet,
      postableData.operationsWallet,
      postableData.burnWallet,
      postableData.maxWalletPct,
      postableData.maxTxPct,
    ],
    value: [parseEther("0.0000001")],
    onError(error) {
      console.error(error);
    },
  });

  const {
    data: deflationaryTokenWaitData,
    isLoading: deflationaryTokenWaitLoading,
  } = useWaitForTransaction({
    hash: deflationaryTokenData?.hash,
    onSuccess(data, error) {
      if (data) {
        handeShowPopup(data);
      }
    },
  });

  useContractEvent({
    address: "0x29312F062a7A91D1e3c0E04A9D2967B74013de36",
    abi: DTFactories,
    eventName: "TokenDeployed",
    listener(
      parameter1,
      parameter2,
      parameter3,
      parameter4,
      parameter5,
      parameter6
    ) {
      // console.log(
      //   parameter1,
      //   parameter2,
      //   parameter3,
      //   parameter4,
      //   parameter5,
      //   parameter6
      // );
    },
  });

  const handleCreateToken = () => {
    try {
      deployTokenWrite();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="review-token-cont">
      <div className="review-token-head-cont">
        <div className="review-token-icon-cont">
          <img src={ReviewTokenIcon} alt="" />
        </div>
        <div>Review Your Token</div>
      </div>

      <div className="review-token-box">
        <div className="rt-box-head">Token Information</div>
        <div className="rt-box-data">
          <div>Token Name</div>
          <div>{state.tokenInfo.tokenName}</div>
        </div>
        <div className="rt-box-data">
          <div>Token Symbol</div>
          <div>{state.tokenInfo.tokenSymbol}</div>
        </div>
        <div className="rt-box-data">
          <div>Blockchain Network</div>
          <div>{state.tokenInfo.chainNetwork || "Base Sepolia"}</div>
        </div>
      </div>

      <div className="review-token-box">
        <div className="rt-box-head">Token Supply</div>
        <div className="rt-box-data">
          <div>Initial Supply</div>
          <div>{state.tokenSupply.initialSupply}</div>
        </div>
        <div className="rt-box-data">
          <div>Maximum Supply</div>
          <div>{state.tokenSupply.initialSupply}</div>
        </div>
      </div>

      <div className="review-token-box">
        <div className="rt-box-head">Liquidity Options</div>
        <div className="rt-box-data">
          <div>Token Supply</div>
          <div>{state.liquidityOption.tokenAmount}%</div>
        </div>
        <div className="rt-box-data">
          <div>ETH Amount</div>
          <div>
            {state.liquidityOption.ethAmount
              ? state.liquidityOption.ethAmount
              : 0}
          </div>
        </div>
        <div className="rt-box-data">
          <div>Launch Market Cap</div>
          <div>
            {state.liquidityOption.launchMarketCap} ETH (~13,856.56 USD)
          </div>
        </div>
        <div className="rt-box-data">
          <div>Launch Token Price</div>
          <div>{state.liquidityOption.launchTokenPrice} ETH (~1.39 USD)</div>
        </div>
        <div className="rt-box-data">
          <div>Token Ownership</div>
          <div>{conciseAddress(address)}</div>
        </div>
      </div>

      <div className="rt-btn-cont">
        <button
          className="grey-btn"
          onClick={() => handleSetSession("UtDeployToken")}>
          <img src={LeftArrow} alt="" />
        </button>
        <button className="gradient-btn" onClick={handleCreateToken}>
          {deflationaryTokenLoading || deflationaryTokenWaitLoading
            ? "Loading"
            : `Launch ${state.tokenInfo.tokenName} To Base Sepolia`}
        </button>
      </div>

      {/* <div className="review-token-box review-token-box-phone">
        <div className="rtt-top-sec">
          <div className="rtt-top-head-sec rth-top-res">
            <div
              className="rtt-arrow-left"
              onClick={() => handleSetSession("DividenDeployToken")}></div>
            <div className="main-left-token">Token Information</div>
          </div>
          <div className="rt-box-data">
            <div>Token Name</div>
            <div>{state.tokenInfo.tokenName}</div>
          </div>
          <div className="rt-box-data">
            <div>Token Symbol</div>
            <div>{state.tokenInfo.tokenSymbol}</div>
          </div>
          <div className="rt-box-data">
            <div>Blockchain Network</div>
            <div>{state.tokenInfo.chainNetwork || "Base Sepolia"}</div>
          </div>
        </div>

        <div className="rtt-section">
          <div className="rt-box-head">Token Supply</div>
          <div className="rt-box-data">
            <div>Initial Supply</div>
            <div>{state.tokenSupply.initialSupply}</div>
          </div>
        </div>

        <div className="rtt-section">
          <div className="rt-box-head">Liquidity Options</div>
          <div className="rt-box-data">
            <div>Token Supply</div>
            <div>{state.liquidityOption.tokenAmount}%</div>
          </div>
          <div className="rt-box-data">
            <div>ETH Amount</div>
            <div>{state.liquidityOption.ethAmount ? state.liquidityOption.ethAmount : 0 }</div>
          </div>
          <div className="rt-box-data">
            <div>Launch Market Cap</div>
            <div>
              {state.liquidityOption.launchMarketCap} ETH (~13,856.56 USD)
            </div>
          </div>
          <div className="rt-box-data">
            <div>Launch Token Price</div>
            <div>{state.liquidityOption.launchTokenPrice} ETH (~1.39 USD)</div>
          </div>
          <div className="rt-box-data">
            <div>Token Ownership</div>
            <div>{conciseAddress(address)}</div>
          </div>
        </div>

        <div className="rt-btn-cont phone-size-button">
          <button className="gradient-btn" onClick={handleCreateToken}>
            {deflationaryTokenLoading || deflationaryTokenWaitLoading
              ? "Loading"
              : "Launch To Blockchain"}
          </button>
        </div>
      </div> */}
    </div>
  );
}
