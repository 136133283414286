import React from "react";
import "../../ultimate-token/sub-components/SubComponents.css";
import Toggle from "../../../utills/Toggle/Toggle";
import { useUltimateTokenContext } from "../../../context/UltimateContext";
import MyInput from "../../ultimate-token/sub-components/MyInput";
import { useNavigate } from "react-router-dom";
// import SelectTag from "../../../utills/select/Select.js";

export default function DividendTokenInformation({ handleSetSession }) {
  const navigate = useNavigate();

  const { state, dispatch } = useUltimateTokenContext();

  const handleInputChage = (field, value) => {
    dispatch({
      type: "UPDATE_FORM_DATA",
      section: "tokenInfo",
      payload: { [field]: value },
    });
  };

  const handleChange = (field, value) => {
    handleInputChage(field, value);
  };

  const setToggleValue = (field, data) => {
    handleInputChage(field, data);
  };

  return (
    <section className="token-create-main-cont">
      <div className="box-bg">
        <div className="token-create-heading-cont">
          <div>Token Information</div>
          <div>Basic details about your token.</div>
        </div>

        <div className="token-input-cont-col2">
          <div className="ticc2-input">
            <div>Token Name</div>
            <input
              type="text"
              value={state.tokenInfo.tokenName}
              onChange={(e) => handleInputChage("tokenName", e.target.value)}
              name="token-name"
              placeholder="Your Token"
            />
          </div>

          <div className="ticc2-input">
            <div>Token Symbol</div>
            <input
              type="text"
              name="token-name"
              placeholder="BASED"
              value={state.tokenInfo.tokenSymbol}
              onChange={(e) => handleInputChage("tokenSymbol", e.target.value)}
            />
          </div>
        </div>

        <div className="ticc2-select-cont">
          <div>Blockchain Network</div>
          <select
            value={state.tokenInfo.chainNetwork || ""}
            onChange={(e) => handleInputChage("chainNetwork", e.target.value)}>
            <option disabled value="">
              Please Select
            </option>
            {/* <option value="Ethereum">Ethereum (Mainnet)</option>
            <option value="BNB Smart Chain">BNB Smart Chain (Mainnet)</option>
            <option value="Polygon">Polygon (Mainnet)</option>
            <option value="Base">Base (Mainnet)</option>
            <option value="OP Mainnet">OP Mainnet (Mainnet)</option>
            <option value="Arbitrum">Arbitrum One (Mainnet)</option>
            <option value="Avalanche">Avalanche (Mainnet)</option> */}
            <option value="Base Sepolia">Base Sepolia</option>
            {/* <option value="BNB Testnet">Binance Smart Chain (Testnet)</option> */}
          </select>

          {/* <SelectTag /> */}
        </div>

        <div className="tc-toggle-cont">
          <div>
            <div>Custom Decimals</div>
            <div>Change the number of decimals for your token.</div>
          </div>

          <Toggle
            value={state.tokenInfo.customDecimal}
            setToggleValue={setToggleValue}
            keyValue={"customDecimal"}
          />
        </div>
        {state.tokenInfo.customDecimal && (
          <div className="tc-range-cont">
            <MyInput
              min={0}
              max={18}
              value={state.tokenInfo.changeDecimal}
              param={"changeDecimal"}
              handleChange={handleChange}
            />
            <span className="tc-decimal-count">
              {state.tokenInfo.changeDecimal}
            </span>
          </div>
        )}
      </div>

      <div className="nxt-prev-btn-cont">
        <button className="grey-btn" onClick={() => navigate("/create-token")}>
          Previous
        </button>
        <button
          className="gradient-btn"
          onClick={() => handleSetSession("DividendTokenSupply")}>
          Next
        </button>
      </div>
    </section>
  );
}
