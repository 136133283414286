import React from "react";
import SuccessIcon from "../../assets/success-mark 1.png";
import "./Modal.css";
import { Link, useNavigate } from "react-router-dom";

export default function ContactPopup() {
  const navigate = useNavigate();
  return (
    <div>
      <div
        style={{
          position: "fixed",
          top: "0",
          left: "0",
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          color: "#ffffff",
          marginTop: "20px",
        }}>
        <div className="popup_container">
          <img className="success_icon_img_contact" src={SuccessIcon} alt="" />
          <div className="dep_suc_cont_contact">
            <div className="contact-modal-text">
              <p>Welcome to Blueprint and thank you for contacting us!</p>
              <p>Your email has been received and we will review it shortly.</p>
              <p>
                In the meantime, if you have any questions please feel free to
                view our FAQ page!
              </p>
            </div>
            <Link to={"/create-token"} className="vw_ln_tkn_contact">
              <p className="vw_ln_tkn_txt">Close</p>
            </Link>

            <Link to={"/faqs"} className="vw_ln_tkn_contact">
              <p className="vw_ln_tkn_txt">FAQ</p>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
