import React from "react";
import "../../ultimate-token/sub-components/SubComponents.css";
import MyInput from "../../ultimate-token/sub-components/MyInput";
import { useUltimateTokenContext } from "../../../context/UltimateContext";

export default function DividendConfigureFees({ handleSetSession }) {
  const { state, dispatch } = useUltimateTokenContext();

  const handleInputChange = (field, value) => {
    dispatch({
      type: "UPDATE",
      section: "configureFees",
      payload: { [field]: value },
    });
  };

  const handleChange = (field, value) => {
    handleInputChange(field, value);
  };

  return (
    <section className="token-create-main-cont">
      <div className="box-bg tc-liq-cont">
        <div className="token-create-heading-cont">
          <div>Configure Fees</div>
          <div>Let's set the fees for your token.</div>
        </div>

        <div className="tc-toggle-cont tc-box-responsive">
          <div>
            <div>Liquidity Fees</div>
            <div>
              The percentage of the transaction that will be added to the
              liquidity pool. Maximum of 10%.
            </div>
          </div>
        </div>

        <div className="dt-tc-liq-section-cont">
          <div className="tc-liq-token-supply tc-liq-token-suppl-res">
            <div>
              <div>Liquidity Fee (Buy)</div>
              <div className="tc-liq-total-supply-bar-cont tc-liq-tl-bar-res">
                <MyInput
                  min={0}
                  max={10}
                  value={state.configureFees.liqFeeBuy}
                  param={"liqFeeBuy"}
                  handleChange={handleChange}
                />
                <div>{state.configureFees.liqFeeBuy}%</div>
              </div>
            </div>

            <div className="dt-ticc2-select-cont">
              <div>Exchange</div>
              <select
                value={state.configureFees.liqExchange || ""}
                onChange={(e) =>
                  handleInputChange("liqExchange", e.target.value)
                }>
                <option disabled value="">
                  Please Select
                </option>
                <option value="0x1926f2BEc4cd34613ccAaC82911204B87cf590a2">
                  Bluedex (Main-net)
                </option>
                <option value="0xAEDfd05a6d0129670751b379C1623a0FD0bFBa16">
                  Bluedex (Test-net)
                </option>
              </select>
            </div>
          </div>
        </div>

        <div className="dt-tc-config">
          <div className="tc-toggle-cont tc-box-responsive">
            <div>
              <div>Marketing/Operations Fee</div>
              <div>
                The percentage of the transaction that will be sent to wallet
                set here. Maximum of 10%.
              </div>
            </div>
          </div>

          <div className="tc-dividend-config-fee br-cnt-2x">
            <div>
              <div>Marketing/Operations Fee (Buy)</div>
              <div className="tcdcf-bar-cont tc-liq-tl-bar-res ">
                <MyInput
                  min={0}
                  max={10}
                  value={state.configureFees.markOpFeeBuy}
                  param={"markOpFeeBuy"}
                  handleChange={handleChange}
                />
                <div>{state.configureFees.markOpFeeBuy}%</div>
              </div>
            </div>
          </div>

          <div className="dt-ticc2-select-cont xnjk-ksdn">
            <div>Marketing/Operations Wallet</div>
            <input
              type="text"
              name="markopwallet"
              value={state.configureFees.markOpWallet}
              onChange={(e) =>
                handleInputChange("markOpWallet", e.target.value)
              }
            />
          </div>
        </div>
      </div>

      <div className="nxt-prev-btn-cont">
        <button
          className="grey-btn"
          onClick={() => handleSetSession("DividendConfigureDividends")}>
          Previous
        </button>
        <button
          className="gradient-btn"
          onClick={() => handleSetSession("DividendLiquidityOption")}>
          Next
        </button>
      </div>
    </section>
  );
}
