import React from "react";
import SuccessIcon from "../../assets/success-mark 1.png";
import "./Modal.css";
import { Link } from "react-router-dom";
import { useChainId } from "wagmi";

export default function ContractDeployed({ depContAddress }) {
  const chainId = useChainId();
  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          color: "#ffffff",
          marginTop: "20px",
        }}>
        <div className="popup_container">
          <img className="success_icon_img" src={SuccessIcon} alt="" />
          <div className="dep_suc_cont">
            <p style={{ display: "inline-block" }} className="suc_modal_txt1">
              Contract Deployed Successfully!
            </p>
            <p className="suc_modal_txt2">Contract Address: {depContAddress}</p>

            <Link to={"/create-token"} className="vw_ln_tkn">
              <p className="vw_ln_tkn_txt">Close</p>
            </Link>

            <a
              href={`${
                chainId === 84532
                  ? "https://sepolia.basescan.org"
                  : chainId === 8453 && "https://basescan.org"
              }/token/${depContAddress}`}
              target="_blank"
              className="vw_ln_tkn">
              <p className="vw_ln_tkn_txt">View Token on Explorer</p>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
