import React from "react";
import "./TCTopContent.css";
import TokenCreatorBadge from "../../TokenCreatorBadge";
import WalletConnectBtn from "../../wallet/WalletConnectBtn";

export default function TCTopContent({ TCTopData }) {
  return (
    <section className="token-creation-container">
      <div className="token-creation-top-cont">
        <div>{TCTopData?.heading}</div>
        <p>{TCTopData?.subHeading}</p>

        {TCTopData?.badgeData.map((value, key) => (
          <TokenCreatorBadge key={key} badgeValue={value} badgeFor={'tokenCreate'} />
        ))}

        <WalletConnectBtn btnFor={'NotHomePage'}/>
      </div>
    </section>
  );
}
