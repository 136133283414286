import "./App.css";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Navbar from "./components/navbar/Navbar";
import CreateToken from "./components/create-token/CreateToken";
import UltimateToken from "./components/ultimate-token/UltimateToken";
import DeflationaryToken from "./components/deflationary-token/DeflationaryToken";
import DividendToken from "./components/dividend-token/DividendToken";
import FAQ from "./components/faq/FAQ";
import Contact from "./components/contact/Contact";
import TermsCondition from "./components/termsCondition/TermsCondition";
import { UltimateTokenProvider } from "./context/UltimateContext";
import { DeflationaryTokenProvider } from "./context/DeflationaryContext";
import { useAccount, useChainId } from "wagmi";
import { useEffect } from "react";
// import DN404Token from './components/dn404-token/DN404Token';

function App() {
  const { address } = useAccount();
  const chainId = useChainId();

  useEffect(() => {
    if (address && chainId === 84532) {
      sessionStorage.setItem("chain", "Base Sepolia");
    } else if (address && chainId === 8453) {
      sessionStorage.setItem("chain", "Base");
    } else if (!address) {
      sessionStorage.clear();
    }
  }, [address]);

  return (
    <BrowserRouter>
      <UltimateTokenProvider>
        <DeflationaryTokenProvider>
          <Navbar />
          <Routes>
            <Route exact path="/" element={<Navigate to={"create-token"} />} />
            <Route exact path="/create-token" element={<CreateToken />} />
            <Route
              exact
              path="/create-token/core-token"
              element={<UltimateToken />}
            />
            <Route
              exact
              path="/create-token/deflationary-token"
              element={<DeflationaryToken />}
            />
            <Route exact path="#" element={<DividendToken />} />
            {/* <Route exact path='/create-token/dn404-token' element={<DN404Token />} /> */}
            <Route exact path="/faqs" element={<FAQ />} />
            <Route exact path="/contact" element={<Contact />} />
            <Route
              exact
              path="/terms-and-conditions"
              element={<TermsCondition />}
            />
            <Route exact path="*" element={<Navigate to={"create-token"} />} />
          </Routes>
        </DeflationaryTokenProvider>
      </UltimateTokenProvider>
    </BrowserRouter>
  );
}

export default App;
