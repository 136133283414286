import React, { useState } from "react";
import "./SubComponents.css";
import Toggle from "../../../utills/Toggle/Toggle";
import { useUltimateTokenContext } from "../../../context/UltimateContext.js";
import MyInput from "./MyInput";
import { useNavigate } from "react-router-dom";
// import SelectTag from "../../../utills/select/Select.js";
import { Tooltip } from "react-tooltip";

export default function UtTokenInformation({ handleSetSession }) {
  const navigate = useNavigate();

  const [inputError, setInputError] = useState({
    tokenName: "",
    tokenSymbol: "",
  });

  const { state, dispatch } = useUltimateTokenContext();

  const handleInputChage = (field, value) => {
    dispatch({
      type: "UPDATE_FORM_DATA",
      section: "tokenInfo",
      payload: { [field]: value },
    });

    // Validate input dynamically
    if (field === "tokenName") {
      if (!value) {
        setInputError((prevData) => ({
          ...prevData,
          tokenName: "Token Name is required!",
        }));
      } else if (value.length < 3 || value.length > 30) {
        setInputError((prevData) => ({
          ...prevData,
          tokenName: "Token Name should be between 3 and 30 characters!",
        }));
      } else if (!/^[a-zA-Z\s]+$/.test(value)) {
        setInputError((prevData) => ({
          ...prevData,
          tokenName: "Token Name should only contain alphabetic characters!",
        }));
      } else {
        setInputError((prevData) => ({ ...prevData, tokenName: "" }));
      }
    } else if (field === "tokenSymbol") {
      if (!value) {
        setInputError((prevData) => ({
          ...prevData,
          tokenSymbol: "Token Symbol is required!",
        }));
      } else if (value.length < 3 || value.length > 7) {
        setInputError((prevData) => ({
          ...prevData,
          tokenSymbol: "Symbol must be between 3 and 7 characters!",
        }));
      } else if (!/^[a-zA-Z\s]+$/.test(value)) {
        setInputError((prevData) => ({
          ...prevData,
          tokenSymbol:
            "Token Symbol should only contain alphabetic characters!",
        }));
      } else {
        setInputError((prevData) => ({ ...prevData, tokenSymbol: "" }));
      }
    }
  };

  const handleChange = (field, value) => {
    handleInputChage(field, value);
  };

  const setToggleValue = (field, data) => {
    handleInputChage(field, data);
  };

  const handleNextComponent = () => {
    let isValid = true;

    if (!state.tokenInfo.tokenName) {
      setInputError((prevData) => ({
        ...prevData,
        tokenName: "Token Name is required!",
      }));
      isValid = false;
    } else if (
      state.tokenInfo.tokenName.length < 3 ||
      state.tokenInfo.tokenName.length > 30
    ) {
      setInputError((prevData) => ({
        ...prevData,
        tokenName: "Token Name should be between 3 and 30 characters!",
      }));
      isValid = false;
    } else if (!/^[a-zA-Z\s]+$/.test(state.tokenInfo.tokenName)) {
      setInputError((prevData) => ({
        ...prevData,
        tokenName: "Token Name should only contain alphabetic characters!",
      }));
      isValid = false;
    } else {
      setInputError((prevData) => ({ ...prevData, tokenName: "" }));
    }

    if (!state.tokenInfo.tokenSymbol) {
      setInputError((prevData) => ({
        ...prevData,
        tokenSymbol: "Token Symbol is required!",
      }));

      isValid = false;
    } else if (
      state.tokenInfo.tokenSymbol.length < 3 ||
      state.tokenInfo.tokenSymbol.length > 7
    ) {
      setInputError((prevData) => ({
        ...prevData,
        tokenSymbol: "Symbol must be less than 3 & greater than 7 character!",
      }));

      isValid = false;
    } else if (!/^[a-zA-Z\s]+$/.test(state.tokenInfo.tokenSymbol)) {
      setInputError((prevData) => ({
        ...prevData,
        tokenSymbol: "Token Symbol should only contain alphabetic characters!",
      }));

      isValid = false;
    } else {
      setInputError((prevData) => ({ ...prevData, tokenSymbol: "" }));
    }

    if (isValid) {
      handleSetSession("UtAdditionalFeature");
    }
  };

  return (
    <section className="token-create-main-cont">
      <div className="box-bg">
        <div className="token-create-heading-cont">
          <div>Token Information</div>
          <div>Basic details about your token.</div>
        </div>

        <div className="token-input-cont-col2">
          <div className="ticc2-input">
            <div className="input-heading-cont">
              Token Name{" "}
              <span id="manualButton" className="tooltip-design">
                i
              </span>
            </div>

            <input
              type="text"
              maxLength={30}
              value={state.tokenInfo.tokenName}
              onChange={(e) => handleInputChage("tokenName", e.target.value)}
              name="token-name"
              placeholder="Your Token"
            />
            {inputError.tokenName && (
              <p className="input_error">{inputError.tokenName}</p>
            )}

            <Tooltip
              anchorSelect="#manualButton"
              className="styletooltip"
              style={{
                backgroundColor: "#12141a",
                borderRadius: "12px",
                zIndex: "11111",
                width: "300px",
                textAlign: "center",
              }}>
              Enter the desired name for your token. This name will be
              permanently associated with your token and cannot be changed after
              deployment.
            </Tooltip>
          </div>

          <div className="ticc2-input">
            <div className="input-heading-cont">
              Token Symbol{" "}
              <span id="utTokenSymbol" className="tooltip-design">
                i
              </span>
            </div>

            <input
              type="text"
              minLength={3}
              maxLength={7}
              name="token-name"
              placeholder="BASED"
              value={state.tokenInfo.tokenSymbol}
              onChange={(e) => handleInputChage("tokenSymbol", e.target.value)}
            />
            {inputError.tokenSymbol && (
              <p className="input_error">{inputError.tokenSymbol}</p>
            )}

            <Tooltip
              anchorSelect="#utTokenSymbol"
              className="styletooltip"
              style={{
                backgroundColor: "#12141a",
                borderRadius: "12px",
                zIndex: "11111",
                width: "300px",
                textAlign: "center",
              }}>
              Input the symbol representing your token. This symbol will be used
              to identify your token, For example on exchanges and wallets. Once
              set, it cannot be modified.
            </Tooltip>
          </div>
        </div>

        <div className="ticc2-select-cont">
          <div className="input-heading-cont">
            Blockchain Network{" "}
            <span id="utbn" className="tooltip-design">
              i
            </span>
          </div>
          <select
            value={
              state.tokenInfo.chainNetwork || sessionStorage.getItem("chain") || ""
            }
            onChange={(e) => handleInputChage("chainNetwork", e.target.value)}>
            <option disabled value="">Please Select</option>
            <option value="Base">Base (Mainnet)</option>
            <option value="Base Sepolia">Base Sepolia</option>
            {/* <option value="Ethereum">Ethereum (Mainnet)</option>
            <option value="BNB Smart Chain">BNB Smart Chain (Mainnet)</option>
            <option value="Polygon">Polygon (Mainnet)</option>
            <option value="Base">Base (Mainnet)</option>
            <option value="OP Mainnet">OP Mainnet (Mainnet)</option>
            <option value="Arbitrum">Arbitrum One (Mainnet)</option>
          <option value="Avalanche">Avalanche (Mainnet)</option>
            <option value="BNB Testnet">Binance Smart Chain (Testnet)</option> */}
          </select>

          {/* <SelectTag /> */}

          <Tooltip
            anchorSelect="#utbn"
            className="styletooltip"
            style={{
              backgroundColor: "#12141a",
              borderRadius: "12px",
              zIndex: "11111",
              width: "300px",
              textAlign: "center",
            }}>
            Choose the initial blockchain network where your token will operate.
            This determines the environment for your token's deployment. You can
            redeploy your token on different networks in the future if needed.
          </Tooltip>
        </div>

        <div className="tc-toggle-cont">
          <div>
            <div className="input-heading-cont">
              Custom Decimals{" "}
              <span id="utcd" className="tooltip-design">
                i
              </span>
            </div>
            <div>Change the number of decimals for your token.</div>
          </div>

          <Toggle
            value={state.tokenInfo.customDecimal}
            setToggleValue={setToggleValue}
            keyValue={"customDecimal"}
          />

          <Tooltip
            anchorSelect="#utcd"
            className="styletooltip"
            style={{
              backgroundColor: "#12141a",
              borderRadius: "12px",
              zIndex: "11111",
              width: "300px",
              textAlign: "center",
            }}>
            Decimals determine the precision of your token's value. Choosing the
            right number of decimals ensures accuracy and usability in
            transactions. Standard tokens typically use 18 decimals, but
            customising this can optimise your token's functionality for
            specific use cases.
          </Tooltip>
        </div>
        {state.tokenInfo.customDecimal && (
          <div className="tc-range-cont">
            <MyInput
              min={0}
              max={18}
              value={state.tokenInfo.changeDecimal}
              param={"changeDecimal"}
              handleChange={handleChange}
            />
            <span className="tc-decimal-count">
              {state.tokenInfo.changeDecimal}
            </span>
          </div>
        )}
      </div>

      <div className="nxt-prev-btn-cont">
        <button className="grey-btn" onClick={() => navigate("/create-token")}>
          Previous
        </button>
        <button className="gradient-btn" onClick={handleNextComponent}>
          Next
        </button>
      </div>
    </section>
  );
}
