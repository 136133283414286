import React, { useState } from "react";
import "../../ultimate-token/sub-components/SubComponents.css";
import MyInput from "../../ultimate-token/sub-components/MyInput";
import { Tooltip } from "react-tooltip";
import { useDeflationaryTokenContext } from "../../../context/DeflationaryContext";

export default function DtConfigureFees({ handleSetSession }) {
  const { state, dispatch } = useDeflationaryTokenContext();

  const [inputError, setInputError] = useState("");
  const [exchangeError, setExchangeError] = useState("");

  const handleInputChange = (field, value) => {
    dispatch({
      type: "UPDATE",
      section: "configureFees",
      payload: { [field]: value },
    });

    const validAddressRegex = /^(0x)?[0-9a-fA-F]{40}$/;

    if (field === "markOpWallet") {
      if (!value) {
        setInputError("Marketing/Operation wallet field is required!");
      } else if (!value.match(validAddressRegex)) {
        setInputError("Marketing/Operation wallet address is not correct!");
      } else {
        setInputError("");
      }
    }

    if (field === "liqExchange") {
      if (!value) {
        setExchangeError("Please Select Exchange first!");
      } else {
        setExchangeError("");
      }
    }
  };

  const handleChange = (field, value) => {
    handleInputChange(field, value);
  };

  const handleNextComponent = () => {
    let isValid = true;
    const validAddressRegex = /^(0x)?[0-9a-fA-F]{40}$/;

    if (!state.configureFees.markOpWallet) {
      setInputError("Marketing/Operation wallet field is required!");
      isValid = false;
    } else if (!state.configureFees.markOpWallet.match(validAddressRegex)) {
      setInputError("Marketing/Operation wallet address is not correct!");
      isValid = false;
    } else {
      setInputError("");
    }

    if (!state.configureFees.liqExchange) {
      setExchangeError("Please Select Exchange first!");

      isValid = false;
    } else {
      setExchangeError("");
    }

    if (isValid) {
      handleSetSession("DtLiquidityOption");
    }
  };

  return (
    <section className="token-create-main-cont">
      <div className="box-bg tc-liq-cont">
        <div className="token-create-heading-cont tc-box-responsive">
          <div>Configure Fees</div>
          <div>Let's set the fees for your token.</div>
        </div>

        <div className="tc-toggle-cont tc-box-responsive">
          <div>
            <div className="input-heading-cont">
              Liquidity Fees
              <span id="dtLiqFee" className="tooltip-design">
                i
              </span>
            </div>
            <div>
              The percentage of the transaction that will be added to the
              liquidity pool. Maximum of 10%.
            </div>
            <Tooltip
              anchorSelect="#dtLiqFee"
              className="styletooltip"
              style={{
                backgroundColor: "#12141a",
                borderRadius: "12px",
                zIndex: "11111",
                width: "350px",
                textAlign: "center",
              }}>
              Determine the percentage of each transaction that will contribute
              to the liquidity pool. The liquidity pool ensures there's enough
              liquidity for trading. You can set different fees for buying and
              selling transactions, with a maximum of 10% for each. This helps
              maintain liquidity and stability in the token's market. This can
              be adjusted via the relevant scan site within the write functions
              at any time.
            </Tooltip>
          </div>
        </div>

        <div className="dt-tc-liq-section-cont">
          <div className="tc-toggle-cont tc-box-responsive">
            <div>
              {/* <div>Token Paring & Funding</div> */}
              <div className="tkp-fund-text">
                Pair a percentage of your token supply with ETH to fund the
                liquidity pool. We recommend pairing at least 10% of your token
                supply with at least 1 ETH.
              </div>
            </div>
          </div>

          <div className="tc-liq-token-supply ">
            <div>
              <div className="input-heading-cont">
                Liquidity Fee (Buy){" "}
                <span id="dtLiqFeeBuy" className="tooltip-design">
                  i
                </span>
              </div>
              <div className="tc-liq-total-supply-bar-cont tc-liq-tl-bar-res">
                <MyInput
                  min={0}
                  max={10}
                  value={state.configureFees.liqFeeBuy}
                  param={"liqFeeBuy"}
                  handleChange={handleChange}
                />
                <div>{state.configureFees.liqFeeBuy}%</div>
              </div>

              <Tooltip
                anchorSelect="#dtLiqFeeBuy"
                className="styletooltip"
                style={{
                  backgroundColor: "#12141a",
                  borderRadius: "12px",
                  zIndex: "11111",
                  width: "350px",
                  textAlign: "center",
                }}>
                The percentage of the transaction amount that will be added to
                the liquidity pool when someone buys your token. This fee helps
                increase liquidity, making it easier for users to buy and sell
                your token.
              </Tooltip>
            </div>

            <div className="tsicc2-input dt-tsicc2-input">
              <div className="input-heading-cont">
                Liquidity Fee (Sell){" "}
                <span id="dtLiqFeeSell" className="tooltip-design">
                  i
                </span>
              </div>
              <div className="tc-liq-total-supply-bar-cont tc-liq-tl-bar-res">
                <MyInput
                  min={0}
                  max={10}
                  value={
                    !state.additional.buySellFees
                      ? state.configureFees.liqFeeBuy
                      : state.configureFees.liqFeeSell
                  }
                  param={"liqFeeSell"}
                  handleChange={handleChange}
                  inputFor={!state.additional.buySellFees ? "sell" : ""}
                />
                <div>
                  {!state.additional.buySellFees
                    ? state.configureFees.liqFeeBuy
                    : state.configureFees.liqFeeSell}
                  %
                </div>
              </div>

              <Tooltip
                anchorSelect="#dtLiqFeeSell"
                className="styletooltip"
                style={{
                  backgroundColor: "#12141a",
                  borderRadius: "12px",
                  zIndex: "11111",
                  width: "350px",
                  textAlign: "center",
                }}>
                The percentage of the transaction amount that will be added to
                the liquidity pool when someone sells your token. This fee
                ensures there's enough liquidity available for selling
                transactions, maintaining market stability.
              </Tooltip>
            </div>
          </div>

          <div className="dt-ticc2-select-cont">
            <div className="input-heading-cont">
              Exchange{" "}
              <span id="dtConfigExchange" className="tooltip-design">
                i
              </span>
            </div>
            <select
              value={state.configureFees.liqExchange || ""}
              onChange={(e) =>
                handleInputChange("liqExchange", e.target.value)
              }>
              <option disabled value="">
                Please Select
              </option>
              <option value="0x1926f2BEc4cd34613ccAaC82911204B87cf590a2">
                Bluedex (Main-net)
              </option>
              <option value="0xAEDfd05a6d0129670751b379C1623a0FD0bFBa16">
                Bluedex (Test-net)
              </option>
            </select>

            <Tooltip
              anchorSelect="#dtConfigExchange"
              className="styletooltip"
              style={{
                backgroundColor: "#12141a",
                borderRadius: "12px",
                zIndex: "11111",
                width: "350px",
                textAlign: "center",
              }}>
              Select Exchange for the token!
            </Tooltip>

            {exchangeError && <p className="input_error">{exchangeError}</p>}
          </div>
        </div>

        <div className="dt-tc-liq-section-cont ">
          <div className="tc-toggle-cont tc-box-responsive">
            <div>
              <div className="input-heading-cont">
                Marketing/Operations Fee
                <span id="dtMarkOpFee" className="tooltip-design">
                  i
                </span>
              </div>
              <div>
                The percentage of the transaction that will be sent to wallet
                set here. Maximum of 10%.
              </div>

              <Tooltip
                anchorSelect="#dtMarkOpFee"
                className="styletooltip"
                style={{
                  backgroundColor: "#12141a",
                  borderRadius: "12px",
                  zIndex: "11111",
                  width: "400px",
                  textAlign: "center",
                }}>
                Specify the percentage of each transaction that will go towards
                marketing or operational expenses. This fee can be customised
                separately for buying and selling transactions, with a maximum
                of 10%. By allocating a portion of transaction fees to marketing
                or operations, you can fund promotional activities and cover
                operational costs to support the growth and sustainability of
                your project. This can be adjusted via the relevant scan site
                within the write functions at any time.
              </Tooltip>
            </div>
          </div>

          <div className="tc-liq-token-supply ">
            <div>
              <div className="input-heading-cont">
                Marketing/Operations Fee (Buy){" "}
                <span id="dtMarkOpFeeBuy" className="tooltip-design">
                  i
                </span>
              </div>
              <div className="tc-liq-total-supply-bar-cont tc-liq-tl-bar-res">
                <MyInput
                  min={0}
                  max={10}
                  value={state.configureFees.markOpFeeBuy}
                  param={"markOpFeeBuy"}
                  handleChange={handleChange}
                />
                <div>{state.configureFees.markOpFeeBuy}%</div>
              </div>

              <Tooltip
                anchorSelect="#dtMarkOpFeeBuy"
                className="styletooltip"
                style={{
                  backgroundColor: "#12141a",
                  borderRadius: "12px",
                  zIndex: "11111",
                  width: "350px",
                  textAlign: "center",
                }}>
                The percentage of the transaction amount that will be allocated
                to marketing or operational expenses when someone buys your
                token. This fee helps finance marketing campaigns, community
                engagement efforts, and project development.
              </Tooltip>
            </div>

            <div className="tsicc2-input dt-tsicc2-input">
              <div className="input-heading-cont">
                Marketing/Operations Fee (Sell){" "}
                <span id="dtMarkOpFeeSell" className="tooltip-design">
                  i
                </span>
              </div>
              <div className="tc-liq-total-supply-bar-cont tc-liq-tl-bar-res">
                <MyInput
                  min={0}
                  max={10}
                  value={
                    !state.additional.buySellFees
                      ? state.configureFees.markOpFeeBuy
                      : state.configureFees.markOpFeeSell
                  }
                  param={"markOpFeeSell"}
                  handleChange={handleChange}
                  inputFor={!state.additional.buySellFees ? "sell" : ""}
                />
                <div>
                  {!state.additional.buySellFees
                    ? state.configureFees.markOpFeeBuy
                    : state.configureFees.markOpFeeSell}
                  %
                </div>
              </div>

              <Tooltip
                anchorSelect="#dtMarkOpFeeSell"
                className="styletooltip"
                style={{
                  backgroundColor: "#12141a",
                  borderRadius: "12px",
                  zIndex: "11111",
                  width: "350px",
                  textAlign: "center",
                }}>
                The percentage of the transaction amount that will be allocated
                to marketing or operational expenses when someone sells your
                token. This fee ensures there are funds available for ongoing
                marketing initiatives and operational activities to enhance
                project visibility and sustainability.
              </Tooltip>
            </div>
          </div>

          <div className="dt-ticc2-select-cont">
            <div className="input-heading-cont">
              Marketing/Operations Wallet{" "}
              <span id="dtMarkOpWallet" className="tooltip-design">
                i
              </span>
            </div>
            <input
              type="text"
              name="markopwallet"
              className="markOpWalletAddress"
              placeholder="0x0000000000000000000000000000000000000000"
              value={state.configureFees.markOpWallet}
              onChange={(e) =>
                handleInputChange("markOpWallet", e.target.value)
              }
            />
            {inputError && <p className="input_error">{inputError}</p>}

            <Tooltip
              anchorSelect="#dtMarkOpWallet"
              className="styletooltip"
              style={{
                backgroundColor: "#12141a",
                borderRadius: "12px",
                zIndex: "11111",
                width: "350px",
                textAlign: "center",
              }}>
              Enter the wallet address where the marketing or operational fees
              will be sent. Make sure to use a secure wallet address controlled
              by your project team to receive these funds securely. This can be
              adjusted via the relevant scan site within the write functions at
              any time.
            </Tooltip>
          </div>
        </div>

        <div className="dt-tc-liq-section-cont-bottom">
          <div className="tc-toggle-cont tc-box-responsive">
            <div>
              <div className="input-heading-cont">
                Burn Fee
                <span id="dtBurnFee" className="tooltip-design">
                  i
                </span>
              </div>
              <div>
                The percentage of the transaction that will be burned. Maximum
                of 10%.
              </div>

              <Tooltip
                anchorSelect="#dtBurnFee"
                className="styletooltip"
                style={{
                  backgroundColor: "#12141a",
                  borderRadius: "12px",
                  zIndex: "11111",
                  width: "350px",
                  textAlign: "center",
                }}>
                Decide the percentage of each transaction that will be burned,
                effectively reducing the token supply over time. Like other
                fees, you can set different burn fees for buying and selling
                transactions, with a maximum of 10%. Burning tokens helps
                control inflation and can increase the scarcity and value of
                your token over time. This can be adjusted via the relevant scan
                site within the write functions at any time.
              </Tooltip>
            </div>
          </div>

          <div className="tc-liq-token-supply">
            <div>
              <div className="input-heading-cont">
                Burn Fee (Buy){" "}
                <span id="dtBurnFeeBuy" className="tooltip-design">
                  i
                </span>
              </div>
              <div className="tc-liq-total-supply-bar-cont tc-liq-tl-bar-res">
                <MyInput
                  min={0}
                  max={10}
                  value={state.configureFees.burnFeeBuy}
                  param={"burnFeeBuy"}
                  handleChange={handleChange}
                />
                <div>{state.configureFees.burnFeeBuy}%</div>
              </div>

              <Tooltip
                anchorSelect="#dtBurnFeeBuy"
                className="styletooltip"
                style={{
                  backgroundColor: "#12141a",
                  borderRadius: "12px",
                  zIndex: "11111",
                  width: "350px",
                  textAlign: "center",
                }}>
                The percentage of the transaction amount that will be burned
                when someone buys your token. This fee reduces the circulating
                supply, potentially increasing the token's scarcity and value.
              </Tooltip>
            </div>

            <div className="tsicc2-input dt-tsicc2-input">
              <div className="input-heading-cont">
                Burn Fee (Sell){" "}
                <span id="dtBurnFeeSell" className="tooltip-design">
                  i
                </span>
              </div>
              <div className="tc-liq-total-supply-bar-cont tc-liq-tl-bar-res">
                <MyInput
                  min={0}
                  max={10}
                  value={
                    !state.additional.buySellFees
                      ? state.configureFees.burnFeeBuy
                      : state.configureFees.burnFeeSell
                  }
                  param={"burnFeeSell"}
                  handleChange={handleChange}
                  inputFor={!state.additional.buySellFees ? "sell" : ""}
                />
                <div>
                  {!state.additional.buySellFees
                    ? state.configureFees.burnFeeBuy
                    : state.configureFees.burnFeeSell}
                  %
                </div>
              </div>

              <Tooltip
                anchorSelect="#dtBurnFeeSell"
                className="styletooltip"
                style={{
                  backgroundColor: "#12141a",
                  borderRadius: "12px",
                  zIndex: "11111",
                  width: "350px",
                  textAlign: "center",
                }}>
                The percentage of the transaction amount that will be burned
                when someone sells your token. This fee further reduces the
                circulating supply, contributing to the long-term deflationary
                mechanism of the token.
              </Tooltip>
            </div>
          </div>
        </div>
      </div>

      <div className="nxt-prev-btn-cont">
        <button
          className="grey-btn"
          onClick={() => handleSetSession("DtTokenSupply")}>
          Previous
        </button>
        <button className="gradient-btn" onClick={handleNextComponent}>
          Next
        </button>
      </div>
    </section>
  );
}
